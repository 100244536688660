import {Button} from "primereact/button";
import {AppFooter} from "../AppFooter";
import {useTranslation} from "react-i18next";
import {layoutColorMode} from '../constants/Constants';
import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {Company} from "../models/Company";
import {CompanyFrontendService} from "../services/frontend/CompanyFrontendService";
import {LoadingSpinner} from "../components/LoadingSpinner";
import {Link} from "react-router-dom";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {WhistleBlowingHeader} from "./WhistleBlowingHeader";
import PathUtil from "../util/PathUtil";
import {OccurrenceFrontendService} from "../services/frontend/OccurrenceFrontendService";

export const WhistleBlowing = (props) => {

    const {t} = useTranslation();
    const { token } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [visibleDialog, setVisibleDialog] = useState(false);
    const [password, setPassword] = useState("");

    let [company, setCompany] = useState(new Company());

    useEffect(() => {
        setTimeout(async () => {
            const companyService = new CompanyFrontendService();
            await companyService.getFrontend(token)
                .then(response => {
                    if (response.status === 200) {
                        setCompany(response.data);
                        setIsLoading(false);
                    }
                });
        }, 1000);
    }, []);

    const showCheckDialog = () => {
        setVisibleDialog(true);
    }
    const hideCheckDialog = () => {
        setVisibleDialog(false);
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }
    
    const checkOccurrence = () => {
        const occurrenceService = new OccurrenceFrontendService();
        occurrenceService.getByUUIDAndSecret(password.substring(0, 36), password.substring(37)).then( response => {
            props.history.push({pathname:
                    new PathUtil().fixedPath(props.location.pathname, "check"),
                search: '', state: response.data });
        });
    }
    
    const handleKeyPress = (e) => {
        if (e.key === "Enter" && password !== ''){
            checkOccurrence();
        }
    }

    const renderFooter = () => {
        return (
            <div>
                <Button label={t('cancel')} icon="pi pi-times" onClick={() => hideCheckDialog()} className="p-button-text" />
                <Button label={t('check')} icon="pi pi-check" onClick={() => checkOccurrence()} style={{margin: "0"}}
                        disabled={password === ''} />
            </div>
        );
    }

    return (
        <div>
            <LoadingSpinner isLoading={isLoading}/>
            <div className="wb-container">
                <WhistleBlowingHeader/>
                {/*    Put the companies text here */}
                <div className="card">
                    <div dangerouslySetInnerHTML={{__html: company.text}}/>
                </div>

                <div className="wb-footer grid">
                    <div className="col-12 md:col-6">
                        <Link to={new PathUtil().fixedPath(props.location.pathname, "submit")} className="textDecorationNone">
                            <Button label={t("create")+" "+t("occurrence")} icon="pi pi-plus"
                                className="floatLeft wid90 font-bold px-5 py-3 p-button-raised p-button-raised white-space-nowrap"/>
                        </Link>
                    </div>
                    <div className="col-12 md:col-6">
                            <Button label={t("check")+" "+t("occurrence")} onClick={() => showCheckDialog()}
                                className="floatRight wid90 font-bold px-5 py-3 p-button-outlined p-button-raised white-space-nowrap"/>

                    </div>
                </div>
            </div>
            <AppFooter layoutColorMode={layoutColorMode}/>
            <Dialog header={t("check")+" "+t("occurrence")} visible={visibleDialog} onHide={() => hideCheckDialog('displayResponsive')}
                    breakpoints={{'560px': '75vw'}} style={{width: '24vw',minWidth: "400px"}} footer={renderFooter()}>
                <div style={{paddingTop: '15px'}}>
                        <span className="p-float-label">
                            <InputText id="password" className="wid100" value={password}
                                       onChange={handlePasswordChange} onKeyPress={handleKeyPress} autoFocus/>
                            <label htmlFor="password">{t('password')}</label>
                        </span>
                </div>
            </Dialog>
        </div>
    );
}

export default WhistleBlowing;