import {InputText} from "primereact/inputtext";
import {AppFooter} from "../AppFooter";
import {BASE_DOMAIN, layoutColorMode} from "../constants/Constants";
import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import { Toast } from 'primereact/toast';
import {Button} from "primereact/button";
import {Font, Page, Text, View, Document, StyleSheet, usePDF} from '@react-pdf/renderer';
import moment from "moment";
import {Checkbox} from "primereact/checkbox";
import {WhistleBlowingHeader} from "./WhistleBlowingHeader";


export const WhistleBlowingSubmitted = (props) => {

    const {t} = useTranslation();
    const toast = useRef(null);
    const [copied, setCopied] = useState(false);
    const [checked, setChecked] = useState(false);
    Font.register({
        family: "Roboto",
        src:
            "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"
    });
    const MyDoc = (
        <Document>
            <Page>
                <View style={{ color: 'black', textAlign: 'left', margin: 30, fontFamily : "Roboto"}}>
                    <Text style={{fontSize:"20px", marginBottom:"10px"}}>{t("file_head")}</Text>
                    <Text >{t("file_instructions")}</Text>
                    <Text style={{fontSize:"14px", marginBottom:"10px"}}>{t("file_navigate")}</Text>
                    <strong>
                        <Text style={{fontSize:"14px", fontWeight:"bold", color:"red"}}>{BASE_DOMAIN}whistle-blowing/a3f1a78b-7b9a-4f68-95c4-8092d103aa11</Text>
                    </strong>
                    <Text style={{fontSize:"14px", marginTop:"10px"}}>{t("file_follow")}</Text>
                    <Text style={{fontSize:"14px"}}>{t("file_enter_password")}</Text>
                    <Text style={{marginTop:"10px"}}>{t('password_to_save')}</Text>
                    <Text style={{border:"1px solid green", marginTop:"10px", textAlign:"center"}}>
                        {props.location.state.uuid + ":"+ props.location.state.secret}
                    </Text>
                    <Text style={{marginTop:"10px"}}>{t("file_do_not_share")}</Text>
                </View>
            </Page>
        </Document>
    );
    const [instance, updateInstance] = usePDF({ document: MyDoc });

    const continueRedirect = () => {
        props.history.push({ pathname: "." });
    }

    const handleCopy = async (e) => {
        copyKey(e.target.value);
    }
    const handleBtnCopy = () => {
        copyKey(props.location.state.uuid + ":"+ props.location.state.secret);
    }

    const copyKey = async (e) => {
        setCopied(true);
        navigator.clipboard.writeText(e);
        toast.current.show({severity:'success', summary: 'Success', detail:'The password was copied Successfully', life: 5000});
    }

    return(
      <div>
          <Toast ref={toast} />
          <div className="wb-container">
              <WhistleBlowingHeader/>
              <div className="card">
                  <div className="grid">
                      <h2 className="col-12 md:col-12">{t('submitted_occurrence')}</h2>
                      <div className="pb-2 col-12 md:col-12">
                            <span>
                                <label className="font-bold text-red-600">{t('important')}</label>
                                <label className="">{t('submitted_text')}</label>
                            </span>
                      </div>
                      <div className="pb-2 col-12 md:col-12">
                            <span>
                                <label htmlFor="description">{t('password_to_save')}</label>
                                <div className="p-inputgroup">
                                <InputText id="description"  className="wid100 font-bold text-center"
                                           onClick={handleCopy}
                                               value={props.location.state.uuid + ":"+ props.location.state.secret}/>
                                    <Button label={copied ?  t('copied') : t('copy')} style={{marginTop:"5px"}}
                                           onClick={handleBtnCopy}/>
                                </div>
                            </span>
                      </div>

                      <div className="pb-2 col-12 md:col-12 text-center">
                            <span>
                                  <a href={instance.url} download={"crsys_org_"+moment().format('YYYY_MM_DD_HH_mm')+".pdf"}>
                                      <Button label={t('download')}/>
                                  </a>
                            </span>
                      </div>

                      <div className="field-checkbox">
                          <Checkbox inputId="binary" checked={checked} onChange={e => setChecked(e.checked)} />
                          <label htmlFor="binary" style={{fontWeight:"bolder"}}>{t("saved_confirmation")}</label>
                      </div>
                  </div>
              </div>

              <div className="wb-footer grid">
                  <div className="col-12 md:col-4">
                      <Button label={t("continue")} onClick={continueRedirect}
                              disabled={!checked} icon="pi pi-check"
                              className="floatLeft wid90 font-bold px-5 py-3 p-button-raised p-button-raised white-space-nowrap"/>
                  </div>
              </div>
          </div>
          <AppFooter layoutColorMode={layoutColorMode}/>
      </div>
    );
}
export default WhistleBlowingSubmitted;