import {AppFooter} from "../AppFooter";
import {layoutColorMode} from "../constants/Constants";
import React from "react";
import {useParams} from "react-router";
import {WhistleBlowingHeader} from "./WhistleBlowingHeader";
import {OccurrenceSubmitComponent} from "../components/partial/OccuranceSubmitComponent";

export const WhistleBlowingSubmit = (props) => {

    const { token } = useParams();

    const onSavedSuccess = (response) => {
        props.history.push({pathname: "./submitted", search: '', state: response.data});
    }

    return (
        <div>
            <div className="wb-container">
                <WhistleBlowingHeader/>
                <div className="card">
                    <OccurrenceSubmitComponent token={token} onSaved={onSavedSuccess} source='SYSTEM' />
                </div>
            </div>
            <AppFooter layoutColorMode={layoutColorMode}/>
        </div>
    );
}
export default WhistleBlowingSubmit;