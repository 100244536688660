
export class Payment {

    constructor(userUuid, amount, company, paymentType) {
        this.userUuid = userUuid;
        this.amount = amount;
        this.company = company;
        this.paymentType = paymentType;
    }

}

export default Payment;