import { BASE_DOMAIN } from '../constants/Constants';
import api from "./api";
import authHeader from "./AuthHeader";

var service_path = 'company';
export class CompanyFrontendService {

    async edit(companyFront){
        return api.put( BASE_DOMAIN + "api/"+service_path+"/front-update", companyFront, {headers: authHeader()});
    }

}