import File from "../models/File";

export default class FileUtil {

    loadFileList = async (files) => {
        let fileList = [];
        let i = 0;
        for (const file of files) {
            if (file) {
                const tempFile = new File();
                tempFile.name = file.name;
                tempFile.size = file.size;
                tempFile.type = file.type;
                if (file !== undefined) {
                    await this.getBase64FromFile(file, (result) => {
                        tempFile.content = result.toString().substring(result.toString().indexOf(",") + 1);
                    });
                }

                fileList[i] = tempFile;
                i++;
            }
        }
        return fileList;
    }

    getBase64FromFile = async (file, cb) => {
        await this.blobToBase64(file).then(result =>{
            cb(result);
        });
    }

    blobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();

            reader.onload = () => {
                resolve(reader.result);
            };

            reader.onerror = reject;

            reader.readAsDataURL(blob);
        });
    }
}