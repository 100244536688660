import {CompanyService} from "../services/CompanyService";
import React, {useEffect, useRef, useState} from "react";
import {DataTable} from "primereact/datatable";
import {useTranslation} from "react-i18next";
import {Button} from "primereact/button";
import '../assets/css/DataTable.css';
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {Column} from "primereact/column";
import {InputSwitch} from "primereact/inputswitch";
import {Company} from "../models/Company";
import LanguageUtil from "../util/LanguageUtil";
import {UserService} from "../services/UserService";
import dateFormat from "dateformat";
import {DATE_FORMAT} from "../Constants";
import {PaymentService} from "../services/PaymentService";
import {PaymentTableComponent} from "./partial/PaymentTableComponent";
export const CompanyComponent = () => {

    const { t } = useTranslation();
    const languageUtil = new LanguageUtil();
    const [loading, setLoading] = useState(true);
    const [companyList, setCompanyList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState(null);
    const [newCompany, setNewCompany] = useState([]);
    const [createCompanyDialog, setCreateCompanyDialog] = useState(false);
    const [editCompanyDialog, setEditCompanyDialog] = useState(false);
    const [selectedCompanyStatus, setSelectedCompanyStatus] = useState(false);
    const [paymentList, setPaymentList] = useState([]);

    const isMounted = useRef(false);

    useEffect(() => {
        setTimeout(() => {
            isMounted.current = true;
            setLoading(true);
            loadList();
            loadUserList();
        }, 1000);
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }, [loading]);

    const loadList = () => {
        const companyService = new CompanyService();
        companyService.getList()
            .then(response => {
                setCompanyList(response.data);
                setLoading(false);
            });
    }

    const loadUserList = () => {
        const userService = new UserService();
        userService.getList()
            .then(response => {
                setUserList(response.data);
            });
    }

    const loadPaymentList = (companyId) => {
        const paymentService = new PaymentService();
        paymentService.getPaymentByCompany(companyId)
            .then(response => {
                setPaymentList(response.data);
                setLoading(false);
            });
    }

    const dialogFuncMap = {
        'displayResponsive': setCreateCompanyDialog,
        'editCompanyDialog': setEditCompanyDialog
    }

    const renderNewFooter = (name) => {
        return (
            <div>
                <Button label={t('cancel')} icon="pi pi-times" onClick={() => hideNewCompanyDialog(name)} className="p-button-text" />
                <Button label={t('save')} icon="pi pi-check" onClick={() => createNewCompany()} style={{margin: "0"}}
                        disabled={newCompany === ''} />
            </div>
        );
    }

    const renderEditFooter = (name) => {
        return (
            <div>
                <Button label={t('cancel')} icon="pi pi-times" onClick={() => hideEditCateogryDialog()} className="p-button-text" />
                <Button label={t('save')} icon="pi pi-check" onClick={() => editCompany()} style={{margin: "0"}}
                        disabled={ (selectedCompany.name === '' && selectedCompany.vatNumber === '')} />
            </div>
        );
    }

    const showNewCompanyDialog = () => {
        setNewCompany("");
        dialogFuncMap['displayResponsive'](true);
    }

    const hideNewCompanyDialog = () => {
        dialogFuncMap['displayResponsive'](false);
    }

    const showEditCateogryDialog = () => {
        dialogFuncMap['editCompanyDialog'](true);
    }

    const hideEditCateogryDialog = () => {
        dialogFuncMap['editCompanyDialog'](false);
    }

    const handleNewCompanyChange = (e) => {
        setNewCompany(e.target.value);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _company = {...selectedCompany};
        _company[`${name}`] = val;

        setSelectedCompany(_company);
    }

    const onStatusChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _company = {...selectedCompany};
        _company[`${name}`] = val ? 'ACTIVE':'INACTIVE';
        setSelectedCompany(_company);
        setSelectedCompanyStatus(val);
    }

    const onUsersChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _company = {...selectedCompany};
        _company[`${name}`] = val;
        setSelectedCompany(_company);
        setSelectedUsers(val);
    }

    const createNewCompany = async () => {
        const companyService = new CompanyService();
        // if (languageUtil.getLanguage() === "bg-BG"){
        //     selectedCompany.company = newCompany;
        // } else {
        //     selectedCompany.nameEn = newCompany;
        // }
        await companyService.create(selectedCompany).then( response => {
            loadList();
            hideNewCompanyDialog();
        });
    }

    const editCompany = async () => {
        const companyService = new CompanyService();
        console.log("selectedCompany on edit : " + selectedCompany);
        await companyService.edit(selectedCompany)
            .then(response => {
                // setSelectedCompany({...response.data});
            loadList();
            hideEditCateogryDialog();
        });
    }

    const handleKeyPress = (e) => {
        if (e.key === "Enter" && newCompany !== ''){
            createNewCompany();
        }
    }

    const handleRowClick = (row) =>{
        setSelectedCompany({...row.value});
        setSelectedUsers({...row.value.userList});
        setSelectedCompanyStatus( row.value.status === "ACTIVE");
        loadPaymentList(row.value.companyId);
        showEditCateogryDialog();
    }

    const dateBody = (rowData) => {
        return (
            <React.Fragment>
                <div className="product-list-detail">
                    <div>
                        {dateFormat(rowData.registrationDate, DATE_FORMAT)}
                    </div>
                </div>
            </React.Fragment>
        );
    }
    const nameBody = (rowData) => {
        return (
            <React.Fragment>
                <div className="product-list-detail">
                    <div>{ rowData.name }</div>
                </div>
            </React.Fragment>
        );
    }

    const vatBody = (rowData) => {
        return (
            <React.Fragment>
                <div>
                    <span>{rowData.vatNumber}</span>
                </div>
            </React.Fragment>
        );
    }

    const phoneBody = (rowData) => {
        return (
            <React.Fragment>
                <div>
                    <span>{rowData.phone}</span>
                </div>
            </React.Fragment>
        );
    }

    const activeToDate = (rowData) => {
        return (
            <React.Fragment>
                <div>
                    <span>{dateFormat(rowData.activeToDate, DATE_FORMAT)}</span>
                </div>
            </React.Fragment>
        );
    }

    const statusBody = (rowData) => {
        return (
            <React.Fragment>
                <div className="product-list-action">
                    <span className={`product-badge status-${rowData.status.toLowerCase()}`}>{t(rowData.status.toUpperCase())} <i className="pi pi-angle-right"/></span>
                </div>
            </React.Fragment>
        );
    }

    return (
        <div className="dataview">
            <div className="p-dataview-header">
                <h1>{t("companies")}</h1>
                <Button icon="pi pi-plus" label={t("create")+" "+t("company")} onClick={() => showNewCompanyDialog()}/>
                <Dialog header={t("create")+" "+t("company")} visible={createCompanyDialog} onHide={() => hideNewCompanyDialog('displayResponsive')}
                        breakpoints={{'560px': '75vw'}} style={{width: '20vw',minWidth: "400px"}} footer={renderNewFooter()}>
                    <div style={{paddingTop: '15px', paddingBottom: "7.5px"}}>
                        <span className="p-float-label">
                            <InputText id="company_name" className="wid100" value={newCompany.company}
                                       onChange={handleNewCompanyChange} onKeyPress={handleKeyPress} autoFocus/>
                            <label htmlFor="company_name">{t('name')}</label>
                        </span>
                    </div>
                    <div style={{paddingTop: '15px', paddingBottom: "7.5px"}}>
                        <span className="p-float-label">
                            <InputText id="vatNo" className="wid100" value={newCompany.vatNo}
                                       onChange={(e) => onInputChange( e, 'vatNo' )} />
                            <label htmlFor="vatNo">{t("vatNo")}</label>
                        </span>
                    </div>
                    <div style={{paddingTop: '15px', paddingBottom: "7.5px"}}>
                        <span className="p-float-label">
                            <InputText id="phone" className="wid100" value={newCompany.phone}
                                       onChange={(e) => onInputChange( e, 'phone' )} />
                            <label htmlFor="phone">{t("phone")}</label>
                        </span>
                    </div>
                    <div style={{paddingTop: '15px', paddingBottom: "7.5px"}}>
                        <span className="p-float-label">
                            <InputText id="email" className="wid100" value={newCompany.email}
                                       onChange={(e) => onInputChange( e, 'email' )} />
                            <label htmlFor="email">{t("email")}</label>
                        </span>
                    </div>
                    <div className="card">
                        <label>{t("user")}</label>
                        <div style={{paddingTop: '15px', paddingBottom: "7.5px"}}>
                        <span className="p-float-label">
                            <InputText id="givenName" className="wid100" value={newCompany.givenName}
                                       onChange={(e) => onInputChange( e, 'givenName' )} />
                            <label htmlFor="givenName">{t("first_name")}</label>
                        </span>
                        </div>
                        <div style={{paddingTop: '15px', paddingBottom: "7.5px"}}>
                        <span className="p-float-label">
                            <InputText id="familyName" className="wid100" value={newCompany.familyName}
                                       onChange={(e) => onInputChange( e, 'familyName' )} />
                            <label htmlFor="familyName">{t("last_name")}</label>
                        </span>
                        </div>

                    </div>
                    <div style={{paddingTop: '7.5px'}}>
                        <h5>{t('status')}</h5>
                        {/*<p style={{width: "75%", float: "left"}}>{t('select_status_hint')}</p>*/}
                        <InputSwitch checked={selectedCompanyStatus} style={{float: "right"}}
                                     onChange={(e) => onStatusChange(e, 'status' )} >

                        </InputSwitch>
                    </div>
                </Dialog>
            </div>
                <div className="card">
                    <DataTable value={companyList} loading={loading}
                               selectionMode={"single"} onSelectionChange={handleRowClick}>
                        <Column field="registration_date" body={dateBody} style={{ minWidth: '7rem' }} sortable={true}
                                header={t("registration_date")}/>
                        <Column field="name" body={nameBody} style={{ minWidth: '18rem' }}  sortable={true}
                                header={t("company")}/>
                        <Column field="vatNo" body={vatBody} style={{ minWidth: '1rem', textAlign: "right" }}
                                header={t("vat_number")}/>
                        <Column field="phone" body={phoneBody} style={{ minWidth: '1rem', textAlign: "right" }}
                                header={t("phone")}/>
                        <Column field="activeToDate" body={activeToDate} style={{ minWidth: '1rem', textAlign: "right" }}  sortable={true}
                                header={t("active_to")}/>
                        <Column field="status" body={statusBody} style={{ minWidth: '9rem', textAlign: "right" }}
                                header={t("status")}/>
                    </DataTable>

                    <Dialog header={t("edit")+" "+t("company")} visible={editCompanyDialog} onHide={() => hideEditCateogryDialog()}
                            breakpoints={{'560px': '75vw'}} style={{width: '40vw',minWidth: "400px"}} footer={renderEditFooter()}
                    className="edit-company-dialog">
                        <div style={{paddingTop: '15px', paddingBottom: "7.5px"}}>
                            <span className="p-float-label">
                                <InputText id="company_name" className="wid100" value={selectedCompany.name}
                                           onChange={(e) => onInputChange( e, 'name' )} autoFocus />
                                <label htmlFor="company_name">{t("name")}</label>
                            </span>
                        </div>
                        <div style={{paddingTop: '15px', paddingBottom: "7.5px"}}>
                            <span className="p-float-label">
                                <InputText id="vatNo" className="wid100" value={selectedCompany.vatNo}
                                           onChange={(e) => onInputChange( e, 'vatNo' )} />
                                <label htmlFor="vatNo">{t("vatNo")}</label>
                            </span>
                        </div>
                        <hr/>
                        <div style={{paddingTop: '7.5px', paddingBottom: "7.5px"}}>
                            {/*<p>{t('payments')}</p>*/}

                            <div className="dataview wid100">
                                <PaymentTableComponent selectedCompany={selectedCompany} />
                            </div>
                        </div>
                        <hr/>
                        <div style={{paddingTop: '7.5px'}}>
                            <h5>{t('status')}</h5>
                            <p style={{width: "75%", float: "left"}}>{t('select_status_hint')}</p>
                            <InputSwitch checked={selectedCompanyStatus} style={{float: "right"}}
                                         onChange={(e) => onStatusChange(e, 'status' )} >

                            </InputSwitch>
                        </div>
                    </Dialog>
                </div>
        </div>
    );
}