import React from 'react';
import packageJson from '../package.json';
import {useTranslation} from "react-i18next";

export const AppFooter = (props) => {

    const { t } = useTranslation();
    return (
        <div className="layout-footer mt-8">
            <div className="grid p-fluid wid100 align-content-center">
                <div className="col-12">
                    <div className="p-inputgroup align-content-center">
                        <a href="https://crsys.org/" target="_blank" className="ml-auto mr-auto">
                            <img src={t('logo_url')} alt="crsys-logo" height="40" className="mr-2" />
                                <strong className="vertical-align-super">(   v. {packageJson.version} ) by</strong>
                        </a>
                    </div>
                </div>
                <div className="col-3 align-content-center ml-auto mr-auto pt-5 pb-5"
                     style={{backgroundColor: "#4A00AD", borderRadius: "10px", minWidth: "fit-content"}}>
                    <div className="p-inputgroup ml-auto mr-auto">
                        <a href="https://transparency.bg/" target="_blank" className="ml-auto">
                            <img src="/assets/images/logo_TI_white.webp" alt="transparency-ogo" height="40" className="mr-2" />
                        </a>
                        &
                        <a href="https://happycompany.ltd" target="_blank" className="mr-auto">
                            <img src="/assets/images/logo_HC_white.webp" alt="happycompany.ltd-ogo" height="40" className="mr-2" />
                        </a>
                    </div>
                </div>
            </div>

            {/*{props.layoutColorMode === 'light' ? 'assets/images/Car and File v5.7 - Black.png' : 'assets/images/logo-white.svg'}*/}
            <div className="font-medium ml-2">
            </div>
        </div>
    );
}
