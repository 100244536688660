import React, {useRef, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Password} from 'primereact/password';
import {classNames} from 'primereact/utils';
import {useTranslation} from 'react-i18next';
import '../assets/css/Form.css';
import {Link} from "react-router-dom";
import {AppFooter} from "../AppFooter";
import AuthService from "../services/auth.service";
import {DEBUG} from "../constants/Constants";

export const Login = (props) => {

    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const loginForm = useRef({});
    const defaultValues = {
        email: '',
        password: '',
    }

    const {control, formState: {errors}, handleSubmit} = useForm({defaultValues});

    const onSubmit = async (data) => {
        setLoading(true);
        setFormData(data);
        setErrorMessage("");
        await AuthService.login(data.email, data.password)
            .then((response) => {
                if (DEBUG) console.log(data);
            })
            .catch((error) => {
                setErrorMessage(t(error.response.data.error));
                console.log(error.response.data.error);
                setLoading(false);
            });
        setLoading(false);
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    return (
        <div className="form-holder">
            <div className="flex justify-content-center">
                <div className="card">
                    <img src={t('logo_vertical_url')} alt="Confidential Reportyng System logo" height={300}
                         className="marginAuto mt-2 mb-3"/>
                    <h1 className="text-900 text-2xl">{t('login')}</h1>
                    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid" ref={loginForm}>
                        <div className="field">
                            <span className="p-float-label p-input-icon-right">
                                <i className="pi pi-envelope"/>
                                <Controller name="email" control={control}
                                            rules={{required: 'Email is required.',
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message: 'Invalid email address. E.g. example@email.com'
                                                }
                                            }}
                                            render={({field, fieldState}) => (
                                                <InputText id={field.name} {...field} autoFocus
                                                           className={classNames({'p-invalid': fieldState.invalid})}
                                                           disabled={loading}/>
                                            )}/>
                                <label htmlFor="email"
                                       className={classNames({'p-error': !!errors.email})}>Email*</label>
                            </span>
                            {getFormErrorMessage('email')}
                        </div>

                        <div className="field">
                            <span className="p-float-label">
                                <Controller name="password" control={control}
                                            rules={{required: 'Password is required.'}}
                                            render={({
                                                         field,
                                                         fieldState
                                                     }) => (
                                                <Password id={field.name} {...field} toggleMask feedback={false}
                                                          className={classNames({'p-invalid': fieldState.invalid})}
                                                          disabled={loading}/>
                                            )}/>
                                <label htmlFor="password"
                                       className={classNames({'p-error': errors.password})}>{t('password')}*</label>
                            </span>
                            {getFormErrorMessage('password')}
                        </div>

                        <div className="field">
                            <div>
                                <div className={'p-error pb-2 font-bold'} dangerouslySetInnerHTML={{__html: errorMessage}}/>
                                {/*<label className={'p-error pb-2 font-bold'}*/}
                                {/*style={{display: errorMessage ? 'block' : 'none'}}>{errorMessage}</label>*/}
                            </div>
                            <Button type="submit" label={t('login')} className="mt-2"
                                    loading={loading} onClick={handleSubmit(onSubmit)}/>
                        </div>
                        <div className="field">
                            <div className="forgot-password wid45 floatLeft">
                                <a className="text-600 font-medium no-underline cursor-pointer textDecorationNone"
                                   href="/forgotPassword">{t('forgot_password')}</a>
                            </div>
                            <div className="wid50 floatRight">
                                <Link to="/register" className="textDecorationNone">
                                    <Button type="button" label={t("go_to_sign_up")} className="p-button"
                                            style={{
                                                backgroundColor: "var(--yellow-400)",
                                                borderColor: "var(--yellow-400)"
                                            }}/>
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <AppFooter layoutColorMode='light'/>
        </div>
    );
}
