import {encode as base64_encode} from "base-64";
import {clientId, clientSecret} from "../constants/Constants";

export default function authHeader() {
    const access_token = localStorage.getItem('access_token');

    if (access_token) {
        return { language: 'bg', Authorization: 'Bearer ' + access_token };
    } else {
        return {language: 'bg', Authorization: "Basic " +
        base64_encode(clientId + ":" + clientSecret)};
    }
}