import {Route} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {CategoryComponent} from "./components/CategoryComponent";
import "primereact/resources/themes/lara-light-teal/theme.css";
import {AppTopbar} from "./AppTopbar";
import {AppFooter} from "./AppFooter";
import {AppMenu} from "./AppMenu";
import {useTranslation} from "react-i18next";
import PrimeReact from 'primereact/api';
import classNames from 'classnames';
import {Dashboard} from "./components/Dashboard";
import {OccurrenceComponent} from "./components/OccurrenceComponent";
import {UserComponent} from "./components/UserComponent";
import {UserEditComponent} from "./components/UserEditComponent";
import {OccurrenceDetailComponent} from "./components/partial/OccurrenceDetailComponent";
import PathUtil from "./util/PathUtil";
import {CompanyInfo} from "./components/CompanyInfo";
import authService from "./services/auth.service";
import {CompanyComponent} from "./components/CompanyComponent";
import {ProfileComponent} from "./components/ProfileComponent";
import {PaymentComponent} from "./components/PaymentComponent";

export const Company = () => {

    const { t } = useTranslation();
    const layoutMode = 'static';
    const layoutColorMode = 'light';
    const inputStyle = 'outlined';
    const ripple = true;
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    let menuClick = false;
    let mobileTopbarMenuClick = false;
    PrimeReact.ripple = true;

    const menu = [
        {
            // label: 'Home',
            items: [/*
                {
                    label: t('dashboard'), icon: 'pi pi-fw pi-home', to: '/company'
                }*/
            ]
        }];

    let i=0;
    menu[0].items[++i] = { label: t('dashboard'), icon: 'pi pi-fw pi-home', to: '/company' };

    if (authService.getCurrentUser().permissions.indexOf("OCCURRENCE_VIEW") > -1){
        menu[0].items[++i] = {label: t('occurrences'), icon: 'pi pi-fw pi-file', to: '/company/occurrence'};
    }
    if (authService.getCurrentUser().permissions.indexOf("CATEGORY_VIEW") > -1){
        menu[0].items[++i] = {label: t('categories'), icon: 'pi pi-fw pi-shield', to: '/company/category'};
    }
    if (authService.getCurrentUser().permissions.indexOf("USER_VIEW") > -1){
        menu[0].items[++i] = {label: t('users'), icon: 'pi pi-fw pi-user', to: '/company/users'};
    }
    if (authService.getCurrentUser().permissions.indexOf("COMPANY_LIST_VIEW") > -1){
        menu[0].items[++i] = {label: t('companies'), icon: 'pi pi-fw pi-user', to: '/company/company'};
    }
    if (authService.getCurrentUser().permissions.indexOf("PAYMENT_VIEW") > -1){
        menu[0].items[++i] = {label: t('payments'), icon: 'pi pi-fw pi-dollar', to: '/company/payment'};
    }

    menu[0].items[++i] = {label: t('profile'), icon: 'pi pi-fw pi-user', to: '/company/profile'};
    menu[0].items[++i] = {label: t('log_out'), icon: 'pi pi-fw pi-sign-out', to: '/logout'};

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const isDesktop = () => {
        return window.innerWidth >= 992;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }

    const onWrapperClick = () => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });
    if (new PathUtil().checkTokenAndRedirect()){
        return (
            <div className={wrapperClass} onClick={onWrapperClick}>
                <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                           mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick}
                           onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}/>

                <div className="layout-sidebar" onClick={onSidebarClick}>
                    <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode}/>
                </div>

                <div className="layout-main-container">
                    <div className="layout-main">
                        <Route path="/" render={() => <Dashboard colorMode={layoutColorMode}/>} exact={true}/>
                        <Route path="/company" render={() => <Dashboard colorMode={layoutColorMode}/>} exact={true}/>
                        <Route path="/company/info" component={CompanyInfo} exact={true} />
                        <Route path="/company/occurrence/:uuid" component={OccurrenceDetailComponent} exact={true}
                               render={authService.getCurrentUser().permissions.indexOf("OCCURRENCE_EDIT") > -1 }/>
                        <Route path="/company/occurrence" component={OccurrenceComponent} exact={true}
                               render={authService.getCurrentUser().permissions.indexOf("OCCURRENCE_VIEW") > -1 }/>
                        <Route path="/company/category" component={CategoryComponent}
                               render={authService.getCurrentUser().permissions.indexOf("CATEGORY_VIEW") > -1 }/>
                        <Route path="/company/company" component={CompanyComponent}
                               render={authService.getCurrentUser().permissions.indexOf("COMPANY_LIST_VIEW") > -1 }/>
                        <Route path="/company/payment" component={PaymentComponent} />
                        <Route path="/company/profile" component={ProfileComponent} />
                        <Route path="/company/user/:uuid" component={UserEditComponent}/>
                        <Route path="/company/users" component={UserComponent}/>
                    </div>
                    <AppFooter layoutColorMode={layoutColorMode}/>
                </div>
            </div>
        );
    }
}

export default Company;