
export class Occurrence {
    constructor(occurrenceId, number, createdOn, lastUpdateOn, subject, description, categoryId,
                categoryName, wbName, wbEmail, wbPhone, wbDistrict, wbCity, wbAddress, source, status, fileList, perpetratorList) {
        this.occurrenceId = occurrenceId;
        this.number = number;
        this.createdOn = createdOn;
        this.lastUpdateOn = lastUpdateOn;
        this.subject = subject;
        this.description = description;
        this.categoryId = categoryId;
        this.categoryName = categoryName;
        this.wbName = wbName;
        this.wbEmail = wbEmail;
        this.wbPhone = wbPhone;
        this.wbDistrict = wbDistrict;
        this.wbCity = wbCity;
        this.wbAddress = wbAddress;
        this.source = source;
        this.status = status;
        this.fileList = fileList;
        this.perpetratorList = perpetratorList;
    }
}

export default Occurrence;