import i18next from "i18next";

export default class LanguageUtil {

    getLanguage(){
        return i18next.language || "bg-BG";
    }

    setLanguage(lang){
        i18next.changeLanguage(lang);
    }

    getTwoLetterCode(fullCode){
        return fullCode.substring(3,5).toLowerCase();
    }
}