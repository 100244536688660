import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import React from "react";

export const FileListTable = (props) => {

    const handleBTN = async () => {
        // axios.get("http://localhost:8080/api/user/get-file/QmYCsxEfRSga9MxwJCgymksNEhXuVrTFbFDrGcRLPor5Ct", {
        //             responseType: 'arraybuffer'
        //         })
        // .then(response => {
        //     const blob = new Blob([response.data], {type: 'application/octet-stream'});
        //     const file = URL.createObjectURL(blob);
        //     let a = document.createElement('a');
        //     a.href = file;
        //     a.download = "test.png";
        //     a.click();
        // })
    }


    const iconsColumn = (rowData) => {
        return(
            <div>
                <a href={"http://78.128.62.2:18080/ipfs/" + rowData.hash} download target="_blank">
                    <i className="pi pi-download"></i>
                </a>
            </div>
        )
    }

    return (
        <div>
            <DataTable value={props.fileList}
                       style={{display: (props.fileList === undefined || props.fileList.length === 0) ? "none":"block"}}>
                <Column body={iconsColumn} style={{width:"10px", padding: "1rem 0"}}/>
                <Column field="name"/>
                <Column field="size"/>
            </DataTable>
        </div>
    );
}