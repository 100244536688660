import { BASE_DOMAIN } from '../../constants/Constants';
import api from "axios";
import authHeader from "../AuthHeader";

var service_path = 'company';
export class CompanyFrontendService {

    async getFrontend(url){
        return api.get( BASE_DOMAIN + "api/"+service_path+"/get-front/" + url, {headers: authHeader()});
    }

}