import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';
import '../assets/css/Form.css';
import {Link} from "react-router-dom";
import {AppFooter} from "../AppFooter";
import ReCAPTCHA from "react-google-recaptcha";
import {RECAPTCHA_SITE_KEY} from "../Constants";
import AuthService from "../services/auth.service";

export const Register = (props) => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    // const [formData, setFormData] = useState({});
    const [messageTitle, setMessageTitle] = useState("");
    const [messageText, setMessageText] = useState("");
    const [errorOnSubmit, setErrorOnSubmit] = useState(false);
    const defaultValues = {
        givenName: '',
        familyName: '',
        email: '',
        phone: '',
        vatNo: '',
        company: '',
        accept: false
    }

    const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });

    const onSubmit = async (data) => {
        setLoading(true);
        // setFormData(data);
        await AuthService.register(data.givenName, data.familyName, data.email, data.phone, data.vatNo, data.company)
            .then(() => {
                setErrorOnSubmit(false);
                setMessageTitle(t("registration_successful"));
                setMessageText(t("registration_text",
                    {userName: data.givenName + " " + data.familyName, userEmail: data.email}
                ));
                setShowMessage(true);
                setLoading(false);
                reset();
            })
            .catch((error) => {
                setErrorOnSubmit(true);
                setMessageTitle("Error!");
                setMessageText("Този e-mail съществува в системат!");
                setShowMessage(true);
                setLoading(false);
            });

    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(false)} /></div>;
    const vatNoHeader = <h6>Pick a vatNo</h6>;
    const vatNoFooter = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </React.Fragment>
    );

    return (
        <div className="form-holder">
            <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                <div className="flex justify-content-center flex-column pt-6 px-3">
                    <i className={ errorOnSubmit ? 'pi pi-times-circle' : 'pi pi-check-circle'} style={{ fontSize: '5rem', color: errorOnSubmit ? 'var(--red-500)' : 'var(--green-500)' }}></i>
                    <h5>{messageTitle}</h5>
                    <p style={{ lineHeight: 1.5, textIndent: '1rem' }} dangerouslySetInnerHTML={{__html: messageText}}/>
                </div>
            </Dialog>

            <div className="flex justify-content-center">
                <div className="card">
                    <img src={t('logo_vertical_url')} alt="Confidential Reportyng System logo" height={300}
                         className="marginAuto mt-2 mb-3"/>
                    <h1 className="text-900 text-2xl">{t('registration')}</h1>
                    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                        {/* Name */}
                        <div className="field col-13 grid">
                            {/* First Name */}
                            <span className="p-float-label md:col-6">
                                <Controller name="givenName" control={control} rules={{ required: 'Name is required.' }} render={({ field, fieldState }) => (
                                    <InputText id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                                )} />
                                <label htmlFor="givenName" className={classNames({ 'p-error': errors.givenName })}>{t('first_name')}*</label>
                            </span>
                            {getFormErrorMessage('givenName')}
                            {/* Last Name */}
                            <span className="p-float-label md:col-6">
                                <Controller name="familyName" control={control} rules={{ required: 'Family Name is required.' }} render={({ field, fieldState }) => (
                                    <InputText id={field.familyName} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                )} />
                                <label htmlFor="name" className={classNames({ 'p-error': errors.familyName })}>{t('last_name')}*</label>
                            </span>
                            {getFormErrorMessage('familyName')}
                        </div>
                        {/* email */}
                        <div className="field floatNone">
                            <span className="p-float-label p-input-icon-right">
                                <i className="pi pi-envelope" />
                                <Controller name="email" control={control}
                                            rules={{ required: 'Email is required.', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Invalid email address. E.g. example@email.com' }}}
                                            render={({ field, fieldState }) => (
                                                <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                            )} />
                                <label htmlFor="email" className={classNames({ 'p-error': !!errors.email })}>Email*</label>
                            </span>
                            {getFormErrorMessage('email')}
                        </div>
                        {/* phone */}
                        <div className="field floatNone">
                            <span className="p-float-label p-input-icon-right">
                                <i className="pi pi-envelope" />
                                <Controller name="phone" control={control}
                                            rules={{ required: 'Phone is required.', pattern: { value: /^\+?[(]?\d{3}\)?[-\s\.]?\d{3}[-\s\.]?\d{4,6}$/,
                                                    message: 'Invalid phone. E.g. 0888888888 / +359 888 888 888' }}}
                                            render={({ field, fieldState }) => (
                                                <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                            )} />
                                <label htmlFor="phone" className={classNames({ 'p-error': !!errors.phone })}>{t("phone")}*</label>
                            </span>
                            {getFormErrorMessage('phone')}
                        </div>
                        {/* company */}
                        <div className="field floatNone">
                            <span className="p-float-label p-input-icon-right">
                                <i className="pi pi-envelope" />
                                <Controller name="company" control={control}
                                            rules={{ required: 'Company is required.'}}
                                            render={({ field, fieldState }) => (
                                                <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                            )} />
                                <label htmlFor="company" className={classNames({ 'p-error': !!errors.company })}>{t('company')}*</label>
                            </span>
                            {getFormErrorMessage('company')}
                        </div>
                        {/* vat No */}
                        <div className="field">
                            <span className="p-float-label">
                                <Controller name="vatNo" control={control}
                                            rules={{ required: 'Vat No is required.', pattern: { value: /^\+?[(]?[A-Z]{2}\)?\d{9,12}$/,
                                                    message: 'Invalid Vat number. E.g. BG111222333' } }} render={({ field, fieldState }) => (
                                    <InputText id={field.name} {...field} togglemask disabled={loading}
                                              className={classNames({ 'p-invalid': fieldState.invalid })}
                                              header={vatNoHeader} footer={vatNoFooter} />
                                )} />
                                <label htmlFor="vatNo" className={classNames({ 'p-error': errors.vatNo })}>{t('vatNo')}*</label>
                            </span>
                            {getFormErrorMessage('vatNo')}
                        </div>
                        {/* accept */}
                        <div className="field-checkbox" style={{textAlign:"center"}}>
                            <div style={{marginLeft:"auto",marginRight:"auto"}}>
                                <Controller name="accept" control={control} rules={{ required: true }}
                                            render={({ field, fieldState }) => (
                                    <Checkbox inputId={field.name} checked={field.value} disabled={loading}
                                              onChange={(e) => field.onChange(e.checked)}
                                              className={classNames({ 'p-invalid': fieldState.invalid })}/>
                                )} />
                                <label htmlFor="accept" className={classNames({ 'p-error': errors.accept })}> {t("agree_to_terms")}</label>
                            </div>
                            {getFormErrorMessage('accept')}
                        </div>
                        <div className="p-field">
                            <span className="p-float-label p-input-icon-right align-content-center align-items-center ">
								<Controller name="captcha" control={control}
                                            rules={{ required: 'Re Captcha is required.'}}
                                            render={({ field, fieldState }) => (
                                                <ReCAPTCHA id={field.name} {...field}
                                                           sitekey={RECAPTCHA_SITE_KEY}
                                                           className={classNames({ 'p-invalid': fieldState.invalid }, "mr-auto")}/>
                                            )} />
							</span>
                            {/*<label htmlFor="captcha" className={classNames({ 'p-error': errors.captcha })}> I agree to the terms and conditions*</label>*/}
                            {getFormErrorMessage('captcha')}
                        </div>

                        <div className="field">
                            <Button type="submit" label={t('registration')} className="mt-2"
                                    loading={loading} onClick={handleSubmit(onSubmit)}/>
                        </div>
                        <div className="field">
                            <div className="forgot-vatNo wid40 floatLeft mt-2">
                                <a className="text-600 font-medium no-underline cursor-pointer textDecorationNone" href="/forgotPassword">{t('forgot_password')}</a>
                            </div>
                            <div className="wid60 floatLeft">
                                <Link to="/login" className="textDecorationNone"><Button type="button" label={t("go_to_login")} className="p-button"
                                        style={{backgroundColor:"var(--pink-400)", borderColor:"var(--pink-400)"}} />
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <AppFooter layoutColorMode='light'/>
        </div>
    );
}
