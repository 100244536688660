import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import React, {useEffect, useRef, useState} from "react";
import dateFormat from "dateformat";
import {DATETIME_FORMAT} from "../Constants";
import {useTranslation} from "react-i18next";
import LanguageUtil from "../util/LanguageUtil";
import {PaymentService} from "../services/PaymentService";
import {Dropdown} from "primereact/dropdown";
import {CompanyService} from "../services/CompanyService";
import {InputNumber} from "primereact";
import Payment from "../models/Payment";
import authService from "../services/auth.service";
import {PaymentTableComponent} from "./partial/PaymentTableComponent";

export const PaymentComponent = () => {

    const [loading, setLoading] = useState(true);

    const isMounted = useRef(false);
    const childRef = useRef();

    useEffect(() => {
        setTimeout(() => {
            isMounted.current = true;
            setLoading(true);
        }, 1000);
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }, [loading]);


    return(
        <div className="dataview">
            <PaymentTableComponent ref={childRef}/>
        </div>
    );
}