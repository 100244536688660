import axios from "axios";
import TokenService from "./auth.service";
import {accessTokenUri, authorizationUri, BASE_DOMAIN, clientId, clientSecret, DEBUG} from '../constants/Constants';
import {encode as base64_encode} from "base-64";

const instance = axios.create({
    baseURL: BASE_DOMAIN + "api",
    headers: {
        "Content-Type": "application/json",
    },
});

instance.interceptors.request.use(
    (config) => {
        const token = TokenService.getLocalAccessToken();
        if (token && !config.url.includes(accessTokenUri)) {
            config.headers["Authorization"] = 'Bearer ' + token;
        } else if (!token) {
            return window.location.href = '/login';
        }
        if (DEBUG) {
            console.log("!config.url.includes(accessTokenUri) = " + !config.url.includes(accessTokenUri));
            console.log("config.headers :" + JSON.stringify(config.headers));
            console.log("/* -----------------  instance.interceptors.request.use REQUEST  ---------------- */ ");
            console.log("URL : " + config.url);
            console.log(JSON.stringify(config));
        }
        return config;
    },
    (error) => {
        if (DEBUG) {
            console.log("/* -----------------  instance.interceptors.request.use REQUEST ERROR ---------------- */");
            console.log(error);
        }
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        if (DEBUG) {
            console.log("/* -----------------  instance.interceptors.response.use RESPONSE  ---------------- */ ");
            console.log(res);
        }
        return res;
    },
    async (err) => {
        if (DEBUG) {
            console.log("/* -----------------  instance.interceptors.response.use RESPONSE ERROR ---------------- */ ");
            console.log(JSON.stringify(err));
        }
        let originalConfig = err.config;
        if (originalConfig && originalConfig.url !== accessTokenUri && (err.response || err.message == 'Network Error')) {
            if (err.response && err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    if (DEBUG) {
                        console.log("/* -----------------  HTTP 401  ---------------- */ ");
                        console.log("/* -----------------  Requesting new Access token  ---------------- */ ");
                        console.log(authorizationUri + "?grant_type=refresh_token&refresh_token=" + TokenService.getLocalRefreshToken());
                    }
                    await instance.post(
                        authorizationUri + "?grant_type=refresh_token&refresh_token=" + TokenService.getLocalRefreshToken(),
                        {},
                        {
                            headers : {"Authorization": "Basic " +
                                    base64_encode(clientId + ":" + clientSecret)}
                        }
                    ).then(function (value) {
                        if (DEBUG){
                            console.log("/* -----------------  Success  ---------------- */ ");
                            console.log("Returned data : " + value);
                        }
                        originalConfig.headers["Authorization"] = 'Bearer ' + value.data.access_token;
                        TokenService.updateLocalAccessToken(value.data.access_token);
                        TokenService.updateLocalRefreshToken(value.data.refresh_token);
                        // originalConfig = value.config;
                        if (DEBUG) {
                            console.log("return instance(originalConfig);");
                            console.log(originalConfig);
                        }
                    });
                } catch (_error) {
                    if (DEBUG) console.log("return Promise.reject(_error);");
                    return Promise.reject(_error);
                }
                originalConfig._retry = false;
                return instance(originalConfig);
            }
        }
        if (DEBUG) console.log("return Promise.reject(err);");
        return Promise.reject(err);
    }
);

export default instance;