import {CategoryService} from "../services/CategoryService";
import React, {useEffect, useRef, useState} from "react";
import {DataTable} from "primereact/datatable";
import {useTranslation} from "react-i18next";
import {Button} from "primereact/button";
import '../assets/css/DataTable.css';
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {Column} from "primereact/column";
import {MultiSelect} from "primereact/multiselect";
import {InputSwitch} from "primereact/inputswitch";
import {Category} from "../models/Category";
import LanguageUtil from "../util/LanguageUtil";
import {UserService} from "../services/UserService";
import authService from "../services/auth.service";
import {CompanyService} from "../services/CompanyService";
export const CategoryComponent = () => {

    const { t } = useTranslation();
    const languageUtil = new LanguageUtil();
    const [loading, setLoading] = useState(true);
    const [categoryList, setCategoryList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    // const [selectedUsers, setSelectedUsers] = useState(null);
    const [newCategory, setNewCategory] = useState("");
    const [createCategoryDialog, setCreateCategoryDialog] = useState(false);
    const [editCategoryDialog, setEditCategoryDialog] = useState(false);
    const [selectedCategoryStatus, setSelectedCategoryStatus] = useState(false);

    const isMounted = useRef(false);

    useEffect(() => {
        setTimeout(() => {
            isMounted.current = true;
            setLoading(true);
            loadList();
            loadUserList();
        }, 1000);
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }, [loading]);

    const loadList = () => {
        const companyService = new CompanyService();

        companyService.getCompanyByUser(authService.getCurrentUser()?.user_name)
            .then(async response => {
                const categoryService = new CategoryService();
                categoryService.getListByCompany(response.data.companyId)
                    .then(response => {
                        console.log(response.data);
                        setCategoryList(response.data);
                        setLoading(false);
                    });
            });
    }

    const loadUserList = () => {
        const userService = new UserService();
        userService.getList()
            .then(response => {
                response.data.forEach( user => {
                   user.roleList = null;
                });
                setUserList(response.data);
            });
    }

    const dialogFuncMap = {
        'displayResponsive': setCreateCategoryDialog,
        'editCategoryDialog': setEditCategoryDialog
    }

    const renderNewFooter = (name) => {
        return (
            <div>
                <Button label={t('cancel')} icon="pi pi-times" onClick={() => hideNewCategoryDialog(name)} className="p-button-text" />
                <Button label={t('save')} icon="pi pi-check" onClick={() => createNewCategory()} style={{margin: "0"}}
                        disabled={newCategory === ''} />
            </div>
        );
    }

    const renderEditFooter = (name) => {
        return (
            <div>
                <Button label={t('cancel')} icon="pi pi-times" onClick={() => hideEditCateogryDialog()} className="p-button-text" />
                <Button label={t('save')} icon="pi pi-check" onClick={() => editCategory()} style={{margin: "0"}}
                        disabled={(!selectedCategory.userList) || selectedCategory.userList.length === 0 || (selectedCategory.nameBg === '' && selectedCategory.nameEn === '')} />
            </div>
        );
    }

    const showNewCategoryDialog = () => {
        setNewCategory("");
        dialogFuncMap['displayResponsive'](true);
    }

    const hideNewCategoryDialog = () => {
        dialogFuncMap['displayResponsive'](false);
    }

    const showEditCateogryDialog = () => {
        dialogFuncMap['editCategoryDialog'](true);
    }

    const hideEditCateogryDialog = () => {
        dialogFuncMap['editCategoryDialog'](false);
    }

    const handleNewCategoryChange = (e) => {
        setNewCategory(e.target.value);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _category = {...selectedCategory};
        _category[`${name}`] = val;

        setSelectedCategory(_category);
    }

    const onStatusChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _category = {...selectedCategory};
        _category[`${name}`] = val ? 'ACTIVE':'INACTIVE';
        setSelectedCategory(_category);
        setSelectedCategoryStatus(val);
    }

    const onUsersChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _category = {...selectedCategory};
        _category[`${name}`] = val;
        setSelectedCategory(_category);
        // setSelectedUsers(val);
    }

    const createNewCategory = async () => {
        const categoryService = new CategoryService();
        const category = new Category();
        if (languageUtil.getLanguage() === "bg-BG"){
            category.nameBg = newCategory;
        } else {
            category.nameEn = newCategory;
        }
        await categoryService.create(category).then( response => {
            loadList();
            hideNewCategoryDialog();
        });
    }

    const editCategory = async () => {
        const categoryService = new CategoryService();
        console.log("selectedCategory on edit : " + selectedCategory);
        await categoryService.edit(selectedCategory)
            .then(response => {
                // setSelectedCategory({...response.data});
            loadList();
            hideEditCateogryDialog();
        });
    }

    const handleKeyPress = (e) => {
        if (e.key === "Enter" && newCategory !== ''){
            createNewCategory();
        }
    }

    const handleRowClick = (row) =>{
        // row.value.category.userList = row.value.userList;
        setSelectedCategory({...row.value.category});
        // setSelectedUsers({...row.value.userList});
        setSelectedCategoryStatus( row.value.category.status === "ACTIVE");
        showEditCateogryDialog();
    }

    const nameBody = (rowData) => {
        return (
            <React.Fragment>
                <div className="product-list-detail">
                    <div className="product-name">{languageUtil.getLanguage() === 'bg-BG' ? rowData.category.nameBg : rowData.category.nameEn}</div>
                </div>

                {/*<span className="image-text">{rowData.name}</span>*/}
            </React.Fragment>
        );
    }

    const usersBody = (rowData) => {
        return (
            <React.Fragment>
                <div className="product-user-count">
                    <span className="product-price">{rowData.userList.length} <i className="pi pi-user"/></span>
                </div>
            </React.Fragment>
        );
    }

    const statusBody = (rowData) => {
        return (
            <React.Fragment>
                <div className="product-list-action">
                    <span className={`product-badge status-${rowData.category.status.toLowerCase()}`}>{t(rowData.category.status.toUpperCase())} <i className="pi pi-angle-right"/></span>
                </div>
            </React.Fragment>
        );
    }

    const userTemplate = (option) => {
        return (
            <div className="user-item">
                <div>{option.firstName + " " + option.lastName}</div>
            </div>
        );
    }

    const selectedUsersTemplate = (option) => {
        if (option) {
            return (
                <div className="user-item user-item-value">
                    <div>{option.firstName + " " + option.lastName}</div>
                </div>
            );
        }
    }

    return (
        <div className="dataview">
            <div className="p-dataview-header">
                <h1>{t("categories")}</h1>
                <Button icon="pi pi-plus" label={t("create")+" "+t("category")} onClick={() => showNewCategoryDialog()}/>
                <Dialog header={t("create")+" "+t("category")} visible={createCategoryDialog} onHide={() => hideNewCategoryDialog('displayResponsive')}
                        breakpoints={{'560px': '75vw'}} style={{width: '20vw',minWidth: "400px"}} footer={renderNewFooter()}>
                    <div style={{paddingTop: '15px'}}>
                        <span className="p-float-label">
                            {/*value={value2} onChange={(e) => setValue2(e.target.value)}*/}
                            <InputText id="new_category_name" className="wid100" value={newCategory}
                                       onChange={handleNewCategoryChange} onKeyPress={handleKeyPress} autoFocus/>
                            <label htmlFor="new_category_name">{t('name')}</label>
                        </span>
                    </div>
                </Dialog>
            </div>
                <div className="card">
                    <DataTable value={categoryList} loading={loading}
                               selectionMode={"single"} onSelectionChange={handleRowClick}>
                        <Column field="name" body={nameBody} style={{ minWidth: '45rem' }} />
                        <Column field="name" body={usersBody} style={{ minWidth: '0.5rem', textAlign: "right" }} />
                        <Column field="name" body={statusBody} style={{ minWidth: '1rem', textAlign: "right" }} />

                    </DataTable>
                    <Dialog header={t("edit")+" "+t("category")} visible={editCategoryDialog} onHide={() => hideEditCateogryDialog()}
                            breakpoints={{'560px': '75vw'}} style={{width: '40vw',minWidth: "400px"}} footer={renderEditFooter()}
                    className="edit-category-dialog">
                        <div style={{paddingTop: '15px', paddingBottom: "7.5px"}}>
                            <span className="p-float-label">
                                <InputText id="category_name" className="wid90" value={selectedCategory.nameBg}
                                           onChange={(e) => onInputChange( e, 'nameBg' )} autoFocus/>
                                <label htmlFor="category_name">Наименование</label>
                                <img src="/assets/images/flag/flag_placeholder.png" style={{margin: "0.75rem 0.75rem"}}
                                     className={`flag flag-bg floatRight`} alt="Български"/>
                            </span>
                        </div>
                        <div style={{paddingTop: '15px', paddingBottom: "7.5px"}}>
                            <span className="p-float-label">
                                <InputText id="category_name" className="wid90" value={selectedCategory.nameEn}
                                           onChange={(e) => onInputChange( e, 'nameEn' )}/>
                                <label htmlFor="category_name">Name</label>
                                <img src="/assets/images/flag/flag_placeholder.png" style={{margin: "0.75rem 0.75rem"}}
                                     className={`flag flag-us floatRight`} alt="English"/>
                            </span>
                        </div>
                        <hr/>
                        <div style={{paddingTop: '7.5px', paddingBottom: "7.5px"}}>
                            <p>{t('select_user_hint')}</p>
                            <MultiSelect value={selectedCategory.userList} options={userList}
                                         className="wid100 multiselect-custom"
                                         onChange={(e) => onUsersChange(e, 'userList')}
                                         itemTemplate={userTemplate} selectedItemTemplate={selectedUsersTemplate}
                                         placeholder="Select a User"/>
                        </div>
                        <hr/>
                        <div style={{paddingTop: '7.5px'}}>
                            <h5>{t('status')}</h5>
                            <p style={{width: "75%", float: "left"}}>{t('select_status_hint')}</p>
                            <InputSwitch checked={selectedCategoryStatus} style={{float: "right"}}
                                         onChange={(e) => onStatusChange(e, 'status' )} >

                            </InputSwitch>
                        </div>
                    </Dialog>
                </div>
        </div>
    );
}