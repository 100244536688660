
export class User {

    constructor(id, userId, email, phone, firstName, lastName, roleList, registrationDate, loginEnabled) {
        this.id = id;
        this.userId = userId;
        this.email = email;
        this.phone = phone;
        this.firstName = firstName;
        this.lastName = lastName;
        this.roleList = roleList;
        this.registrationDate = registrationDate;
        this.loginEnabled = loginEnabled;
    }

}

export default User;