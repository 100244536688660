import { BASE_DOMAIN } from '../constants/Constants';
import authHeader from "./AuthHeader";
import api from "./api";

var service_path = 'payment';
export class PaymentService {
    getList(){
        return api.get( BASE_DOMAIN + "api/"+service_path+"/get", {
            headers:  authHeader()
        });
    }

    async getPaymentByUser(uuid){
        return api.get( BASE_DOMAIN + "api/"+service_path+"/getByUser/" + uuid, {
            headers:  authHeader()
        });
    }

    async getPaymentByCompany(companyId){
        return api.get( BASE_DOMAIN + "api/"+service_path+"/getByCompany/" + companyId, {
            headers:  authHeader()
        });
    }

    async create(payment){
        return api.post( BASE_DOMAIN + "api/"+service_path+"/create",
            payment, {
                headers:  authHeader()
            });
    }

}