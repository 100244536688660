// import {User} from "./User";

export class Category {

    constructor(categoryId, nameBg, nameEn, status, userList) {
        this.categoryId = categoryId;
        this.nameBg = nameBg;
        this.nameEn = nameEn;
        this.status = status;
        this.userList = userList; //User[0];
    }

}