import {UserService} from "../services/UserService";
import React, {useEffect, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import dateFormat from "dateformat";
import {DATETIME_FORMAT} from "../Constants";
import {useTranslation} from "react-i18next";
import { useUA } from 'use-ua-parser-js';

export const ProfileComponent = () => {
    const { t } = useTranslation();
    const [loginHistory, setLoginHistory] = new useState([]);

    const getLoginHistory = () => {
        const userService = new UserService();
        userService.getUserLoginHistory().then(response => {
            setLoginHistory(response.data);
        })
    }
    useEffect(() => {
        getLoginHistory();
    }, []);

    const stampBody = (rowData) => {
        return (
            <React.Fragment>
                <span>{dateFormat(rowData.stamp, DATETIME_FORMAT)}</span>
            </React.Fragment>
        );
    }

    const UserAgentBrowserBody = (row) => {
        const UADetails = useUA(row.userAgent);
        return(
            <div>
                <label htmlFor="description"
                       dangerouslySetInnerHTML={{__html: UADetails.browser.name + " " + UADetails.browser.version}}></label>
            </div>
        )
    }

    const UserAgentOsBody = (row) => {
        const UADetails = useUA(row.userAgent);
        return(
            <div>
                <label htmlFor="description"
                       dangerouslySetInnerHTML={{__html: UADetails.os.name + " " + UADetails.os.version}}></label>
            </div>
        )
    }

    return (
        <div className="dataview">
            <div className="p-dataview-header">
                <h1>{t("login_history")}</h1>
            </div>
            <div className="card">
                <DataTable value={loginHistory} tableStyle={{ minWidth: '50rem' }}>
                    <Column field="stamp" header="Stamp" body={stampBody}></Column>
                    <Column field="address" header="Address"></Column>
                    <Column field="userAgent" header="Browser" body={UserAgentBrowserBody}></Column>
                    <Column field="userAgent" header="Browser" body={UserAgentOsBody}></Column>
                </DataTable>
            </div>
        </div>
    );
}