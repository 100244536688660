import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {useTranslation} from "react-i18next";
import {PaymentService} from "../../services/PaymentService";
import dateFormat from "dateformat";
import {DATETIME_FORMAT} from "../../Constants";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Dropdown} from "primereact/dropdown";
import {InputNumber} from "primereact";
import Payment from "../../models/Payment";
import authService from "../../services/auth.service";
import {CompanyService} from "../../services/CompanyService";
import LanguageUtil from "../../util/LanguageUtil";
import {DEBUG} from "../../constants/Constants";

export const PaymentTableComponent = forwardRef((props, ref) => {

    const { t } = useTranslation();
    const [paymentList, setPaymentList] = useState([]);
    const [newPayment, setNewPayment] = useState(new Payment);
    const [selectedPayment, setSelectedPayment] = useState([]);
    const [loading, setLoading] = useState(true);
    const [createPaymentDialog, setCreatePaymentDialog] = useState(false);
    const [editPaymentDialog, setEditPaymentDialog] = useState(false);
    const [companyList, setCompanyList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState();
    const [amount, setAmount] = useState([]);
    const [selectedType, setSelectedType] = useState();
    const languageUtil = new LanguageUtil();
    const paymentTypeList = [
        { name: t("bank"), code:"BANK" },
        { name: t("cash"), code:"CASH" },
        { name: t("card"), code:"CARD" }
    ];

    useEffect(() => {
        setTimeout(() => {
            setLoading(true);
            loadPaymentList();
            loadCompanyList();
        }, 1000);
    }, []);

    // useImperativeHandle(ref, () => ({
    //         reloadList(){
    //             current:loadPaymentList()
    //         }
    //     })
    // );

    const loadCompanyList = () => {
        const companyService = new CompanyService();
        companyService.getList()
            .then(response => {
                setCompanyList(response.data);
                setLoading(false);
            });
    }

    const hideNewPaymentDialog = () => {
        dialogFuncMap['newPaymentDialog'](false);
    }

    const dialogFuncMap = {
        'newPaymentDialog': setCreatePaymentDialog,
        'editPaymentDialog': setEditPaymentDialog
    }


    const createNewPayment = async () => {
        if (DEBUG){
            console.log(" companyId : " + selectedCompany['companyId']);
            console.log(" amount : " + amount);
            console.log(" selectedType : " + selectedType.code);
        }
        const payment  = new Payment();
        payment.company = selectedCompany['companyId'];
        payment.amount = amount;
        payment.paymentType = selectedType.code;
        payment.userUuid = authService.getCurrentUser()?.user_name;
        console.log(" newPayment : " + payment);
        const paymentService = new PaymentService();
        await paymentService.create(payment).then( response => {
            loadPaymentList();
            hideNewPaymentDialog();
        });

    }

    const loadPaymentList = () => {
        const paymentService = new PaymentService();
        if (props.selectedCompany){
            paymentService.getPaymentByCompany(props.selectedCompany.companyId)
                .then(response => {
                    setPaymentList(response.data);
                    setLoading(false);
                });
        } else {
            paymentService.getList()
                .then(response => {
                    setPaymentList(response.data);
                    setLoading(false);
                });
        }
    }


    const dateBody = (rowData) => {
        return (
            <React.Fragment>
                <div className="product-list-detail">
                    <div className="product-name">
                        {dateFormat(rowData.stamp, DATETIME_FORMAT)}</div>
                </div>
            </React.Fragment>
        );
    }

    const companyNameBody = (rowData) => {
        return (
            <React.Fragment>
                <div className="product-list-detail">
                    <div className="product-name">{ rowData.companyName }</div>
                </div>
            </React.Fragment>
        );
    }

    const amountBody = (rowData) => {
        return (
            <React.Fragment>
                <div>
                    <span>{rowData.amount}</span>
                </div>
            </React.Fragment>
        );
    }

    const userNameBody = (rowData) => {
        return (
            <React.Fragment>
                <div>
                    <span>{rowData.userName}</span>
                </div>
            </React.Fragment>
        );
    }

    const paymentTypeBody = (rowData) => {
        return (
            <React.Fragment>
                <div>
                    <span>{t(rowData.paymentType)}</span>
                </div>
            </React.Fragment>
        );
    }

    const handleRowClick = (row) =>{
        if (!props.selectedCompany){
            setSelectedPayment({...row.value});
        }
        // showEditCateogryDialog();
    }

    const countryOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <div>{option.name} | {option.vatNumber}</div>
            </div>
        );
    };

    const showNewPaymentDialog = () => {
        setNewPayment("");
        if (props.selectedCompany){
            setSelectedCompany(props.selectedCompany);
        }
        dialogFuncMap['newPaymentDialog'](true);
    }

    const renderNewFooter = (name) => {
        return (
            <div>
                <Button label={t('cancel')} icon="pi pi-times" onClick={() => hideNewPaymentDialog(name)} className="p-button-text" />
                <Button label={t('save')} icon="pi pi-check" onClick={() => createNewPayment()} style={{margin: "0"}}
                        disabled={!selectedCompany || !selectedType || amount <= 0} />
            </div>
        );
    }

    return(
        <div>
            <div className="p-dataview-header">
                <h1>{t("payments")}</h1>
                <Button icon="pi pi-plus" label={t("new")+" "+t("payment")} onClick={() => showNewPaymentDialog()}/>
                <Dialog header={t("new")+" "+t("payment")} visible={createPaymentDialog} onHide={() => hideNewPaymentDialog('displayResponsive')}
                        breakpoints={{'560px': '75vw'}} style={{width: '30vw',minWidth: "400px"}} footer={renderNewFooter()}>
                    <div style={{paddingTop: '15px'}}>
                            <span className="p-float-label">
                                <div style={{paddingTop: '15px', paddingBottom: "7.5px"}}>
                                    <span className="p-float-label">
                                            <Dropdown id="company" optionLabel={"name"} options={companyList} filter showFilterClear={true}
                                                      filterBy={"name,vatNumber"} filterPlaceholder={t("search_by_name") + " или " +t("vatNo")}
                                                      value={selectedCompany} onChange={(e) => setSelectedCompany(e.value)}
                                                      placeholder={t("select_company")} itemTemplate={countryOptionTemplate} className={"wid100"}
                                                      disabled={selectedCompany}
                                            />
                                            <label htmlFor="company">{t('company')}</label>
                                    </span>
                                </div>
                                <div style={{paddingTop: '20px', paddingBottom: "7.5px"}}>
                                    <span className="p-float-label">
                                        <InputNumber inputId="currency-bulgarian" mode={"currency"} currency="BGN" locale="bg-BG"
                                                     className={"text-right"}
                                                     value={amount}  className={"wid70"}
                                                     onValueChange={(e) => setAmount(e.value)}
                                        />
                                        <label htmlFor="currency-bulgarian">{t('amount')}</label>
                                    </span>
                                </div>
                                <div style={{paddingTop: '20px', paddingBottom: "7.5px"}}>
                                    <span className="p-float-label">
                                        <Dropdown id="type" optionLabel={"name"} options={paymentTypeList}
                                                  value={selectedType} onChange={(e) => setSelectedType(e.value)}
                                                  placeholder={t("select_payment_type")} className={"wid70"}
                                        />
                                        <label htmlFor="type">{t('payment_type')}</label>
                                    </span>

                                </div>
                            </span>
                    </div>
                </Dialog>
            </div>
            <div className="card">
                <DataTable value={paymentList} loading={loading} emptyMessage={t("no_payments")}
                           selectionMode={props.selectedCompany ? "none" : "single"} onSelectionChange={handleRowClick}>
                    <Column field="stamp" body={dateBody} header={t("date")} style={{ minWidth: '7rem' }} />
                    <Column field="companyName" body={companyNameBody} header={t("company")}
                            style={{minWidth: '18rem', visibility: !props.selectedCompany ? "visible" : "hidden",
                                display: !props.selectedCompany ? "block" : "none"}}/>
                    <Column field="amount" body={amountBody} header={t("amount")} style={{ minWidth: '1rem', textAlign: "right" }} />
                    <Column field="userName" body={userNameBody} header={t("accepted_by")} style={{ minWidth: '1rem', textAlign: "right" }} />
                    <Column field="paymentType" body={paymentTypeBody} header={t("payment_type")} style={{ minWidth: '1rem', textAlign: "right" }} />
                </DataTable>
            </div>
        </div>
    );
})