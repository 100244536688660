import {AUTH_DOMAIN, BASE_DOMAIN} from '../constants/Constants';
import authHeader from "./AuthHeader";
import api from "./api";
import axios from "axios";

let service_path = 'user';
export class UserService {
    getList(){
        return api.get( BASE_DOMAIN + "api/"+service_path+"/list", {
            headers:  authHeader()
        });
    }

    async getUserByUUID(uuid){
        return await api.get( BASE_DOMAIN + "api/"+service_path+"/get/"+uuid , {
            headers:  authHeader()
        });
    }

    async create(userName){
        return api.post( BASE_DOMAIN + "api/"+service_path+"/create",
            userName, {headers:  authHeader()});
    }

    async edit(User){
        return api.put( BASE_DOMAIN + "api/"+service_path+"/update",
            User , {headers:  authHeader()});
    }

    async findUserByTokenAndStamp(uuid, token){
        return await axios.post( AUTH_DOMAIN + 'findForPasswordReset/'+uuid+'/'+token, {});
    }

    async passwordReset(data, uuid, token){
        return await axios.post( BASE_DOMAIN + 'api/'+service_path+'/changePassword/'+uuid+'/'+token, data);
    }

    async getUserLoginHistory(){
        return await api.get( BASE_DOMAIN + "api/user/loginHistory" , {
            headers:  authHeader()
        });
    }
}