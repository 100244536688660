
export class Company {

    constructor(companyFrontId, companyId, text, label, url) {
        this.companyFrontId = companyFrontId;
        this.companyId = companyId;
        this.text = text;
        this.label = label;
        this.url = url;
    }

}