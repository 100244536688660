import {Button} from "primereact/button";
import {AppFooter} from "../AppFooter";
import {useTranslation} from "react-i18next";
import {layoutColorMode} from '../constants/Constants';
import {InputText} from "primereact/inputtext";
import {InputMask} from "primereact/inputmask";
import {useState} from "react";

export const WebForm = () => {

    const [copied, setCopied] = useState(false);

    const handleCopy = async (e) => {
        setCopied(true);
        navigator.clipboard.writeText(e.target.value);
    }
    return (
        <div className="text-center">
            <div className="card wid50 marginAuto">

                <div className="surface-0 text-700 text-center">
                    <div className="text-900 font-bold text-5xl mb-3 pt-8">Welcome to web form</div>
                    <div className="text-700 text-2xl mb-5">Choose a convenient way for you to do it</div>
                </div>
                <div className="p-fluid grid">
                    <div className="field col-12 md:col-6">
                        <span className="p-float-label p-input-icon-left">
                            <InputText id="firstname" className="wid100"/>
                            {/*value={value2} onChange={(e) => setValue2(e.target.value)}*/}
                            <label htmlFor="firstname"> First Name</label>
                            <i className="pi pi-user"/>
                        </span>
                    </div>
                    <div className="field col-12 md:col-6">
                        <span className="p-float-label p-input-icon-left">
                            <InputText id="lastname" className="wid100"/>
                            {/*value={value2} onChange={(e) => setValue2(e.target.value)}*/}
                            <label htmlFor="lastname">Last Name</label>
                            <i className="pi pi-user"/>
                        </span>
                    </div>
                    <div className="field col-12 md:col-6">
                        <span className="p-float-label p-input-icon-left">
                            <InputText id="phone" className="wid100" required={true}/>
                            {/*value={value2} onChange={(e) => setValue2(e.target.value)}*/}
                            <label htmlFor="phone">Phone</label>
                            <i className="pi pi-phone"/>
                        </span>
                    </div>
                    <div className="field col-12 md:col-6">
                        <span className="p-float-label p-input-icon-left">
                            <i className="pi pi-envelope"/>
                            <InputText id="mail" className="wid100" required={true}/>
                            {/*value={value2} onChange={(e) => setValue2(e.target.value)}*/}
                            <label htmlFor="mail">e-mail</label>
                        </span>
                    </div>
                </div>
                <div className="col-12 md:col-4">
                    <h3 htmlFor="caseno">Case #</h3>
                    <span>
                        <InputMask id="caseno" className="p-inputtext-lg"
                                   mask="9999-9999-9999-9999" value="6668720893004978"
                                   placeholder="9999-9999-9999-9999"
                                   onClick={handleCopy}/>
                        <label style={{display: copied ? "grid":"none", color: "red"}}>Copied to clipboard!</label>
                    </span>
                </div>

                <Button label="Submit it online"
                        className="mr-3 font-bold px-5 py-3 p-button-raised p-button-raised white-space-nowrap"/>

                <div style={{height: "20px"}}/>
            </div>
            <AppFooter layoutColorMode={layoutColorMode}/>
        </div>
    );
}

export default WebForm;