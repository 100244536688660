import {useTranslation} from "react-i18next";
import LanguageUtil from "../../util/LanguageUtil";
import React, {useEffect, useState} from "react";
import {Dropdown} from "primereact/dropdown";

export const LanguageSw = (props) => {

    const {t} = useTranslation();
    const languageUtil = new LanguageUtil();
    const selected = languageUtil.getLanguage();
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [menuAnchor, setMenuAnchor] = useState(null);

    const countries = [
        { name: 'Български', code: 'bg-BG' },
        { name: 'English', code: 'en-US' }
    ];

    useEffect(() => {
        setSelectedCountry(countries[selected == 'bg-BG' ? 0 : 1]);
    }, [menuAnchor, selected]);

    const onCountryChange = (e) => {
        if (e.value){
            setSelectedCountry(e.value);
            languageUtil.setLanguage(e.value.code);
        }
    }

    const selectedCountryTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <img alt={option.name} src="/assets/images/flag/flag_placeholder.png"
                         className={`flag flag-${languageUtil.getTwoLetterCode(option.code)}`}/>
                    <div className="floatLeft vertical-align-middle">{option.name}</div>
                </div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    const countryOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <img alt={option.name} src="/assets/images/flag/flag_placeholder.png" className={`flag flag-${languageUtil.getTwoLetterCode(option.code)}`} />
                <div className="floatLeft vertical-align-middle">{option.name}</div>
            </div>
        );
    }

    return (
        <div>
            <Dropdown value={selectedCountry} options={countries} onChange={onCountryChange} optionLabel="name" placeholder="Select a Country"
                      valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} className={props.className} />
        </div>
    );
}