import React, {useEffect, useState} from "react";
import {OccurrenceService} from "../../services/OccurrenceService";
import {useTranslation} from "react-i18next";
import {Chart} from "primereact/chart";

const lightOptions = {
    plugins: {
        legend: {
            labels: {
                color: '#495057'
            }
        }
    }
};
export const OccurrenceCountByType = () => {

    const { t } = useTranslation();
    const [lineData, setLineData] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            loadData();
        }, 1000);
    }, []);

    const loadData = () => {
        const occurrenceService = new OccurrenceService();
        occurrenceService.getStatusCount()
            .then(response => {
                let labels = [];
                let data = [];
                let i = 0;
                response.data.forEach(row => {
                    labels[i] = row.status;
                    data[i] = row.count;
                    i++;
                });
                setLineData({
                    labels: labels,
                    datasets: [
                        {
                            label: t("status"),
                            data: data,
                            fill: true,
                            backgroundColor: [
                                "#FFA726",
                                "#66BB6A",
                                "#42A5F5"
                            ],
                            hoverBackgroundColor: [
                                "#FFB74D",
                                "#81C784",
                                "#64B5F6"
                            ],
                            tension: .4
                        }
                    ]
                });
            });
    }

    return(
        <div>
            <h5>{t('occurrence_by_status')}</h5>
            <Chart type="doughnut" data={lineData} options={lightOptions} />
        </div>
    );
}