import { BASE_DOMAIN } from '../constants/Constants';
import authHeader from "./AuthHeader";
import api from "./api";

var service_path = 'role';
export class RoleService {

    getList(){
        return api.get( BASE_DOMAIN + "api/"+service_path+"/list", {
            headers:  authHeader()
        });
    }

    async create(userName){
        return api.post( BASE_DOMAIN + "api/"+service_path+"/create",
            userName, {headers:  authHeader()});
    }

    async edit(User){
        return api.put( BASE_DOMAIN + "api/"+service_path+"/update",
            User , {headers:  authHeader()});
    }

}