
export default class PathUtil {
    fixedPath(currentPath, newPath){
        return currentPath + (currentPath.endsWith("/") ? "" : "/") + newPath;
    }

    checkTokenAndRedirect(){
        if (!localStorage.getItem('access_token')){
            window.location.href = '/login';
            return false;
        }else{
            return true;
        }
    }
}