
export class File {

    constructor(name, content) {
        this.name = name;
        this.content = content;
    }

}

export default File;