import {FileUpload} from "primereact/fileupload";
import React from "react";
import {useTranslation} from "react-i18next";
import {Button} from "primereact/button";
import {Tag} from "primereact/tag";

export const FileUploadComponent = (props) => {

    const {t} = useTranslation();

    const headerTemplate = (options) => {
        const { className, chooseButton } = options;
        return (
            <div className={className} style={{ display: 'flex', alignItems: 'center'}}>
                <span style={{paddingRight: "10px"}}>{t("files")}</span>
                {chooseButton}
            </div>
        );
    }

    const emptyTemplate = (options) => {
        const { className, chooseButton } = options;
        return (
            <div className="flex align-items-center flex-column className">
                {/*<i className="pi pi-image mt-3 p-5" style={{'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i>*/}
                <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}} className="my-5">Drag and Drop Files Here</span>
                <span>{chooseButton}</span>
            </div>
        )
    }
    const onTemplateRemove = (file, callback) => {
        callback();
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{width: '70%'}}>
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" onClick={() => onTemplateRemove(file, props.onRemove)}
                        className="p-button-outlined p-button-rounded p-button-danger ml-auto" />
            </div>
        )
    }

    return(
        <div>
            <FileUpload ref={props.fileUploadRef} multiple customUpload="true" id="fileUpl"
                        name="file[]" uploadHandler={props.onUpload} itemTemplate={itemTemplate}
                        headerTemplate={headerTemplate} emptyTemplate={emptyTemplate}
            />
        </div>
    )
}