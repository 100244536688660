import React, { useState, useEffect, useRef } from 'react';
import '../assets/css/Dashboard.css';
import {OccurrenceCountByDate} from "./partial/OccurrenceCountByDate";
import {OccurrenceCountByStatus} from "./partial/OccurrenceCountByStatus";
import {OccurrenceCountByType} from "./partial/OccurrenceCountByType";
import {OccurrenceDataTable} from "./partial/OccurrenceDataTable";

export const Dashboard = (props) => {

    const [lineOptions, setLineOptions] = useState(null)

    const applyLightTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
            }
        };

        setLineOptions(lineOptions)
    }

    const applyDarkTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#ebedef'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    }
                },
                y: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    }
                },
            }
        };

        setLineOptions(lineOptions)
    }

    useEffect(() => {
        if (props.colorMode === 'light') {
            applyLightTheme();
        } else {
            applyDarkTheme();
        }
    }, [props.colorMode]);

    return (
        <div className="grid occurrence-container">
            <div className="col-12 xl:col-8 p-chart">
                <div className="card">
                    <OccurrenceDataTable rows="5"/>
                </div>
            </div>

            <div className="col-12 xl:col-4 p-chart">
                <div className="card">
                    <OccurrenceCountByType/>
                </div>
            </div>

            <div className="col-12 xl:col-6 p-chart">
                <div className="card">
                    <OccurrenceCountByDate lineOptions={lineOptions}/>
                </div>
            </div>

            <div className="col-12 xl:col-6 p-chart">
                <div className="card">
                    <OccurrenceCountByStatus/>
                </div>
            </div>
        </div>
    );
}
