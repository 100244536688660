
export class Message {

    constructor(messageId, occurrenceId, description, userVisible, fileList, uuid) {
        this.messageId = messageId;
        this.occurrenceId = occurrenceId;
        this.description = description;
        this.userVisible = userVisible;
        this.fileList = fileList;
        this.uuid = uuid;
    }

}

export default Message;