import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {Dropdown} from "primereact/dropdown";
import {RadioButton} from "primereact/radiobutton";
import {Button} from "primereact/button";
import {FileUploadComponent} from "./FileUploadComponent";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import FileUtil from "../../util/FileUtil";
import LanguageUtil from "../../util/LanguageUtil";
import Occurrence from "../../models/Occurrence";
import {OccurrenceFrontendService} from "../../services/frontend/OccurrenceFrontendService";
import {CategoryFrontendService} from "../../services/frontend/CategoryFrontendService";
import {InputMask} from "primereact/inputmask";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {classNames} from "primereact/utils";
import SignaturePad from "signature_pad";

export const OccurrenceSubmitComponent = (props) => {

    const {t} = useTranslation();
    const languageUtil = new LanguageUtil();
    const [categoryList, setCategoryList] = useState([]);
    const [anonymous, setAnonymous] = useState(false);
    const [smConfidential, setSmConfidential] = useState(false);
    const [smAnonymous, setSmAnonymous] = useState(false);
    const fileUploadRef = useRef(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [newOccurrence, setNewOccurrence] = useState(new Occurrence());

    let emptyPerpetrator = {
        position: '',
        name: ''
    }
    const [submitted, setSubmitted] = useState(false);
    const [perpetrator, setPerpetrator] = useState(emptyPerpetrator);
    const [perpetratorDialog, setPerpetratorDialog] = useState(false);
    const [deletePerpetratorDialog, setDeletePerpetratorDialog] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            loadList();
        }, 1000);
    }, []);

    const openNewDialog = () => {
        setPerpetrator(emptyPerpetrator);
        setSubmitted(false);
        setPerpetratorDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setPerpetratorDialog(false);
    }

    const savePerpetrator = () => {
        setSubmitted(true);
        if (perpetrator.name.trim()) {
            let _newOccurrence = {...newOccurrence};
            if (!_newOccurrence.perpetratorList){
                _newOccurrence.perpetratorList = [];
            }
            _newOccurrence.perpetratorList.push(perpetrator);
            setNewOccurrence(_newOccurrence);
            hideDialog();
        }
    }

    const perpetratorDialogFooter = (
        <React.Fragment>
            <Button label={t("cancel")} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={t("add")} icon="pi pi-check" className="p-button p-button-success" onClick={savePerpetrator} />
        </React.Fragment>
    );

    const loadList = () => {
        const categoryService = new CategoryFrontendService();
        categoryService.getList()
            .then(response => {
                setCategoryList(response.data);
            });
    }

    const createNewOccurrence = () => {
        fileUploadRef.current.upload();
        fileUploadRef.current.clear();
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _newOccurrence = {...newOccurrence};
        _newOccurrence[`${name}`] = val;
        setNewOccurrence(_newOccurrence);
    }

    const onPerpetratorInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _perpetrator = {...perpetrator};
        _perpetrator[`${name}`] = val;
        setPerpetrator(_perpetrator);
    }

    const perpetratorKeyPress = (e, name) => {
        if (e.key === "Enter" && perpetrator && perpetrator.name !== '' && name === 'name'){
            document.getElementById('position').focus();
            return;
        }
        if (e.key === "Enter" && perpetrator && perpetrator.name !== ''){
            savePerpetrator();
        }
    }

    const validateForm = () => {
        return ((newOccurrence.subject === undefined || newOccurrence.subject === '') ||
                (newOccurrence.description === undefined || newOccurrence.description === '') ||
                (selectedCategory === undefined || selectedCategory === null)) ||
            (anonymous === false && (newOccurrence.wbName === undefined || newOccurrence.wbName === '')) ||
            (anonymous === false && (newOccurrence.wbEmail === undefined || newOccurrence.wbEmail === '')) ||
            (anonymous === false && (newOccurrence.wbPhone === undefined || newOccurrence.wbPhone === ''));
    }

    const onUpload = async (event) => {
        await new FileUtil().loadFileList(event.files).then(result => {
            doSave(result);
        });
    }

    const doSave = (files) => {
        const occurrenceService = new OccurrenceFrontendService();
        newOccurrence.categoryId = selectedCategory.categoryId;
        newOccurrence.source = props.source;
        newOccurrence.fileList = files;
        if (props.username !== undefined) {
            newOccurrence.occurrenceUserList = [{uuid: props.username, responsible: true}];
        }
        occurrenceService.create(newOccurrence, props.token)
            .then((response) => {
                props.onSaved(response);
            });
    }
    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const onRowEditComplete = (e) => {
        let _products2 = [...newOccurrence.perpetratorList];
        let { newData, index } = e;

        _products2[index] = newData;

        newOccurrence.perpetratorList = _products2;
    }

    const confirmDeletePerpetrator = (perpetrator) => {
        setPerpetrator(perpetrator);
        setDeletePerpetratorDialog(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" rounded outlined severity="danger" className="p-button-outlined p-button-danger"
                        onClick={() => confirmDeletePerpetrator(rowData)} />
            </React.Fragment>
        );
    };

    const deleteSelectedPerpetrator = () => {
        let _newOccurrence = newOccurrence;
        let _perpetratorList = _newOccurrence.perpetratorList.filter((val) => val !== perpetrator);
        _newOccurrence['perpetratorList'] = _perpetratorList;

        setNewOccurrence(_newOccurrence);
        setDeletePerpetratorDialog(false);
        setPerpetrator(emptyPerpetrator);
    };

    const hideDeletePerpetratorDialog = () => {
        setDeletePerpetratorDialog(false);
    };

    const deleteProductsDialogFooter = (
        <React.Fragment>
            <Button label={t("no")} icon="pi pi-times" className="p-button-text" onClick={hideDeletePerpetratorDialog} />
            <Button label={t("yes")} icon="pi pi-check" className="p-button p-button-danger" onClick={deleteSelectedPerpetrator} />
        </React.Fragment>
    );
    let signaturePad;
    const canvas = document.querySelector("canvas");
    try {
        signaturePad = new SignaturePad(canvas);
    } catch (e){}

    const clearSign = () => {
        signaturePad.clear();
    }

    return (
        <div>
            <div className="grid p-fluid">
                <h2 className={"col-12 md:col-12 "}
                    style={{display: (props.username == undefined) ? "block" : "none"}}>{t('submit_occurrence')}</h2>
                <div className="pb-2 col-12 md:col-12 ">
                            <span>
                                <label htmlFor="subject">{t('title')}</label>
                                <InputText id="subject" className="wid100"
                                           autoFocus value={newOccurrence.subject}
                                           onChange={(e) => onInputChange(e, 'subject')}/>
                            </span>
                </div>
                <div className="pb-2 col-12 md:col-12">
                            <span>
                                <label htmlFor="description"
                                       dangerouslySetInnerHTML={{__html: t('description_long')}}></label>
                                <InputTextarea id="description" className="wid100" value={newOccurrence.description}
                                               rows={10}
                                               onChange={(e) => onInputChange(e, 'description')}/>
                            </span>
                </div>
                <div className="pb-2 col-12 md:col-12">
                    <span>
                        <div className="card p-fluid">
                            <label htmlFor="perpetrator" dangerouslySetInnerHTML={{__html: t('perpetrator_long')}}/>
                            <Button label={t("add")} icon="pi pi-plus" className="p-button-success floatRight wid25"
                                    style={{marginTop: "-20px", marginBottom: "10px"}} onClick={openNewDialog} />
                            <DataTable value={newOccurrence.perpetratorList} editMode="row" dataKey="id" className="clearBoth wid100"
                                       onRowEditComplete={onRowEditComplete} responsiveLayout="scroll" emptyMessage={t("no_perpetrators")}>
                                <Column field="name" header={t("name")} editor={(options) => textEditor(options)} style={{ width: '25%' }}></Column>
                                <Column field="position" header={t("position")} editor={(options) => textEditor(options)} style={{ width: '30%' }}></Column>
                                <Column field="uin" header={t("vat_no")} editor={(options) => textEditor(options)} style={{ width: '30%' }}></Column>
                                <Column body={actionBodyTemplate} exportable={false} style={{ width: '10%' }}/>
                            </DataTable>
                        </div>
                    </span>
                </div>

                <div className="pb-4 col-12 md:col-12 pt-4">
                    <p>{t('categories')}</p>
                    <Dropdown options={categoryList}
                              optionLabel={languageUtil.getLanguage() === "bg-BG" ? "nameBg" : "nameEn"}
                              className="col-12 md:col-12 wid100 multiselect-custom"
                              onChange={(e) => setSelectedCategory(e.value)}
                              value={selectedCategory}
                              placeholder={t('select_category')} maxSelectedLabels={5} scrollHeight="300px"/>
                </div>
                <div className="field-radiobutton col-12 md:col-12 pt-4 mb-0"
                     style={{
                         border: "var(--green-100) 1px solid", borderBottom: "none",
                         borderTopLeftRadius: "12px", borderTopRightRadius: "12px"
                     }}>
                    <RadioButton value={false} itemID="anonymous1" name="anonymous"
                                 onChange={(e) => setAnonymous(e.value)}
                                 checked={anonymous === false}/>
                    <label htmlFor="anonymous1" style={{cursor: "pointer"}}
                           onClick={(e) => setAnonymous(false)}>
                        {t("anonymous_type_confidential")}
                    </label>
                </div>
                <p className="col-12 md:col-12 pl-6 pr-6" htmlFor="anonymous1"
                   style={{border: "var(--green-100) 1px solid", borderTop: "none"}}>
                    <div dangerouslySetInnerHTML={{__html: t("anonymous_type_confidential_text")}}/>
                    <div style={{display: smConfidential ? "block" : "none"}}
                         dangerouslySetInnerHTML={{__html: t("anonymous_type_confidential_text_full")}}/>
                    <Button className="floatRight pt-2 p-button-link" style={{boxShadow: "none"}}
                            onClick={e => {
                                setSmConfidential(!smConfidential)
                            }}>
                        {t(smConfidential ? "show_less" : "show_more")}
                    </Button>
                </p>
                <div className="field-radiobutton col-12 md:col-12 pt-4 mb-0"
                     style={{border: "var(--green-100) 1px solid", borderTop: "none", borderBottom: "none"}}>
                    <RadioButton value={true} itemID="anonymous2" name="anonymous"
                                 onChange={(e) => setAnonymous(e.value)}
                                 checked={anonymous === true}/>
                    <label htmlFor="anonymous2" style={{cursor: "pointer"}}
                           onClick={(e) => setAnonymous(true)}>
                        {t("anonymous_type_anonymous")}
                    </label>
                </div>
                <p className="col-12 md:col-12 pl-6 pr-6" htmlFor="anonymous2"
                   style={{
                       border: "var(--green-100) 1px solid", borderTop: "none",
                       borderBottomLeftRadius: "12px", borderBottomRightRadius: "12px"
                   }}>
                    <div dangerouslySetInnerHTML={{__html: t("anonymous_type_anonymous_text")}}/>
                    <div style={{display: smAnonymous ? "block" : "none"}}
                         dangerouslySetInnerHTML={{__html: t("anonymous_type_anonymous_text_full")}}/>
                    <Button className="floatRight pt-2 p-button-link" style={{boxShadow: "none"}}
                            onClick={e => {
                                setSmAnonymous(!smAnonymous)
                            }}>
                        {t(smAnonymous ? "show_less" : "show_more")}
                    </Button>
                </p>
                <div className="pb-2 col-12 md:col-12" style={{display: anonymous ? "none" : "block"}}>
                        <span>
                            <label htmlFor="wbName">{t('name')} *</label>
                            <InputText id="wbName" className="wid100" value={newOccurrence.wbName} // p-invalid
                                       onChange={(e) => onInputChange(e, 'wbName')} required="true"/>
                        </span>
                </div>
                <div className="col-12 md:col-6 pt-4" style={{display: anonymous ? "none" : "block"}}>
                        <span>
                            <label htmlFor="wbEmail">{t('email')} *</label>
                            <InputText id="wbEmail" className="wid100" value={newOccurrence.wbEmail}
                                       onChange={(e) => onInputChange(e, 'wbEmail')} required="true"/>
                        </span>
                </div>
                <div className="col-12 md:col-6 pt-4" style={{display: anonymous ? "none" : "block"}}>
                        <span>
                            <label htmlFor="wbPhone">{t('phone')} *</label>
                            <InputMask id="wbPhone" mask="0999 999 999" placeholder="0999 999 999"
                                       className="wid100" value={newOccurrence.wbPhone} required={true}
                                       onChange={(e) => onInputChange(e, 'wbPhone')}
                            />
                        </span>
                </div>
                <div className="col-12 md:col-6 pt-4" style={{display: anonymous ? "none" : "block"}}>
                        <span>
                            <label htmlFor="wbDistrict">{t('district')} *</label>
                            <InputText id="wbDistrict" placeholder={t('district')}
                                       className="wid100" value={newOccurrence.wbDistrict} required={true}
                                       onChange={(e) => onInputChange(e, 'wbDistrict')}
                            />
                        </span>
                </div>
                <div className="col-12 md:col-6 pt-4" style={{display: anonymous ? "none" : "block"}}>
                        <span>
                            <label htmlFor="wbCity">{t('city')} *</label>
                            <InputText id="wbCity" className="wid100" value={newOccurrence.wbCity} placeholder={t('city')}
                                       onChange={(e) => onInputChange(e, 'wbCity')} required={true}/>
                        </span>
                </div>
                <div className="pb-2 col-12 md:col-12" style={{display: anonymous ? "none" : "block"}}>
                        <span>
                            <label htmlFor="wbAddress">{t('address')} *</label>
                            <InputText id="wbAddress" className="wid100" value={newOccurrence.wbAddress} placeholder={t('address')}
                                       onChange={(e) => onInputChange(e, 'wbAddress')} required="true"/>
                        </span>
                </div>
                <div className="col-12 md:col-12">
                    <FileUploadComponent fileUploadRef={fileUploadRef} onUpload={onUpload}/>
                </div>

            </div>

            <div className="wb-footer grid">
                <div className="col-12 md:col-12 text-center">
                    {/*<div>*/}
                    {/*    <canvas id="signature-pad" className="signature-pad border-1" width="500" height="200"/>*/}
                    {/*    <p>Място за Вашият подпис &nbsp;*/}
                    {/*    <Button label={t("clear")} onClick={clearSign} className="vertical-align-text-top"/></p>*/}
                    {/*</div>*/}
                    <hr/>
                    <Button label={t("create") + " " + t("occurrence")} onClick={createNewOccurrence}
                            disabled={validateForm()}
                            className=" wid50 font-bold px-5 py-3 p-button-raised p-button-raised white-space-nowrap"/>
                </div>
            </div>
            <Dialog visible={perpetratorDialog} style={{ width: '450px' }} header={t("new") + " " + t("perpetrator")} modal className="p-fluid"
                    footer={perpetratorDialogFooter} onHide={hideDialog}>
                <div className="field">
                    <label htmlFor="name">{t("name") + " / " + t("company")}</label>
                    <InputText id="name" value={perpetrator.name} required autoFocus onKeyPress={(event) => perpetratorKeyPress(event, 'name')}
                               onChange={(e) => onPerpetratorInputChange(e, 'name')}
                               className={classNames({ 'p-invalid': submitted && !perpetrator.name })} />
                    {submitted && !perpetrator.name && <small className="p-error">Name is required.</small>}
                </div>
                <div className="field">
                    <label htmlFor="position">{t("position")}</label>
                    <InputText id="position" value={perpetrator.position} required onKeyPress={(event) => perpetratorKeyPress(event, 'position')}
                                   onChange={(e) => onPerpetratorInputChange(e, 'position')} />
                </div>
                <div className="field">
                    <label htmlFor="uin">{t("vat_no")}</label>
                    <InputText id="position" value={perpetrator.uin} required onKeyPress={(event) => perpetratorKeyPress(event, 'uin')}
                               onChange={(e) => onPerpetratorInputChange(e, 'uin')} />
                </div>
            </Dialog>
            <Dialog visible={deletePerpetratorDialog} style={{ width: '32rem' }} header={t("confirm")} modal
                    breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                    footer={deleteProductsDialogFooter} onHide={hideDeletePerpetratorDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    <label dangerouslySetInnerHTML={{__html: t("confirm_perpetrator_remove", {name: perpetrator.name, position:perpetrator.position})}}/>
                </div>
            </Dialog>
        </div>

    );

}