import {DataScroller} from "primereact/datascroller";
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";
import {MessageService} from "../../services/MessageService";
import dateFormat from "dateformat";
import {DATETIME_FORMAT} from "../../Constants";
import Message from "../../models/Message";
import {useTranslation} from "react-i18next";
import FileUtil from "../../util/FileUtil";
import {FileListTable} from "./FileListTable";
import {FileUploadComponent} from "./FileUploadComponent";
import {Dropdown} from "primereact/dropdown";
import {CLOSE, DEANONYMISE, EXTERNAL, FROM_USER, HIDDEN, INTERNAL, PERPETRATOR} from "../../constants/MessageType";
import {DEBUG} from "../../constants/Constants";

export const OccurrenceMessageComponent = forwardRef((props, ref) => {

    const {t} = useTranslation();
    const [messageText, setMessageText] = useState("");
    const [LBText, setLBText] = useState("");
    const [FPText, setFPText] = useState("");
    const [PRText, setPRText] = useState("");
    const [type, setType] = useState(props.messageType);
    const fileUploadRef = useRef(null);
    const messageTypeArray = [PERPETRATOR, DEANONYMISE, CLOSE];

    const emptyActivity = () => {
        return (
            <div className="text-center">
                <i className="pi pi-exclamation-circle" style={{'fontSize': '3em'}}></i>
                <h2>{t("no_activity")}</h2>
            </div>
        )
    }
    useImperativeHandle(ref, () => ({
        doSendMessage(){
                if (DEBUG) console.log("doSendMessage");
                current:sendMessage();
            }
        })

    );

    const descriptionComponent = (data) =>{
        const desc = data.description;
        if (data.messageType === CLOSE){

            const factualIndex = desc.indexOf("\nLBText");
            const factualText = desc.substring(0, factualIndex);

            const legalIndex = desc.indexOf("\nFPText");
            const legalText = desc.substring(factualIndex+7, legalIndex);

            const financialIndex = desc.indexOf("\nPRText");
            const financialText = desc.substring(legalIndex+7, financialIndex);

            const proposalText = desc.substring(financialIndex+7);

            return(
                <div>
                    <br/>
                    <h4>{t("factual_background")}</h4>
                    <p dangerouslySetInnerHTML={{__html: factualText }}></p>
                    <h4>{t("legal_basis")}</h4>
                    <p dangerouslySetInnerHTML={{__html:legalText }}></p>
                    <h4>{t("financial_penalty")}</h4>
                    <p dangerouslySetInnerHTML={{__html:financialText }}></p>
                    <h4>{t("proposal")}</h4>
                    <p dangerouslySetInnerHTML={{__html:proposalText }}></p>
                    <br/>
                </div>
            )
        } else {
            return(
                <div>
                    <p dangerouslySetInnerHTML={{__html: desc}}></p>
                </div>
            )
        }
    }

    const messageTemplate = (data) => {
        if (DEBUG) console.log("data.userId : "+   data.userId );

        return (
            <div className="message-row">
                <div className={data.uuid !== null ? "text-left" : "text-right"}>
                    <span><strong>{data.uuid !== null ? data.usersName : t("whistle_blower")}</strong></span>
                    <strong style={{color: "#F59E0B"}}>
                        <span className={data.uuid !== null ? "floatRight" : "floatLeft"}>{t(data.messageType)}</span>
                    </strong>
                    {descriptionComponent(data)}
                    <i className="pi pi-clock product-category-icon"> </i>
                    <span className="product-category"> {dateFormat(data.createdOn, DATETIME_FORMAT)}</span>
                    <FileListTable fileList={data.fileList}/>
                </div>
            </div>
        );
    }

    const sendMessage = () => {
        fileUploadRef.current.upload();
        fileUploadRef.current.clear();
    }

    const handleKeyPress = (e) => {
        if ((e.key === "Enter" && e.ctrlKey) && messageText !== ''){
            sendMessage();
        }
    }

    const onUpload = async (event) => {
        await new FileUtil().loadFileList(event.files).then( result => {
            doSave(result);
        });
    }

    const doSave = (files) => {
        const message = new Message();
        message.description = messageText;
        if (props.messageType === CLOSE){
            message.description += "\nLBText" + LBText;
            message.description += "\nFPText" + FPText;
            message.description += "\nPRText" + PRText;
        }
        message.occurrenceId = props.occurrenceId;
        message.userVisible = true;
        message.uuid = props.userId;
        message.messageType = props.messageType;
        const messageService = new MessageService();
        message.fileList = files;
        messageService.create(message).then( result => {
            setMessageText("");
            props.loadMessageList(message.occurrenceId);
        });
    }

    const types = [
        {name: t('INTERNAL'), code: INTERNAL},
        {name: t('EXTERNAL'), code: EXTERNAL},
        {name: t('HIDDEN'), code: HIDDEN}
    ];

    const messTypeDropDown = () => {
        if (props.messageType === FROM_USER || messageTypeArray.includes(props.messageType) ){
            return(
                <div style={{display:"none"}}></div>
            );
        } else {
            return(
                <div>
                    <Dropdown optionLabel="name" value={type} options={types} defaultValue={props.messageType}
                              onChange={(e) => setType(e.value)} placeholder={t(props.messageType)}
                              />
                </div>
            );
        }
    }

    const closingOccuranceContent = () => {
        if (props.messageType === CLOSE){
            return(
                <div>
                    <h4>{t('legal_basis')}</h4>
                    <InputTextarea rows={5} className="wid100 mb-3" value={LBText} onKeyPress={handleKeyPress}
                                   onChange={event => {
                                       setLBText(event.target.value)
                                   }}/>
                    <h4>{t('financial_penalty')}</h4>
                    <InputTextarea rows={5} className="wid100 mb-3" value={FPText} onKeyPress={handleKeyPress}
                                   onChange={event => {
                                       setFPText(event.target.value)
                                   }}/>
                    <h4>{t('proposal')}</h4>
                    <InputTextarea rows={5} className="wid100 mb-3" value={PRText} onKeyPress={handleKeyPress}
                                   onChange={event => {
                                       setPRText(event.target.value)
                                   }}/>
                </div>
            );
        }
    }

    const titleContent = () => {
        switch (props.messageType) {
            case PERPETRATOR :{
                return(
                    <div>
                        <p>На път сте да деанимизирате лицата, срещу които е подаден сигнала.</p>
                        <h2 className="col-12 md:col-12 text-2xl mb-2">
                            {t("reason")}
                        </h2>
                    </div>
                )
            }
            case DEANONYMISE :{
                return(
                    <h2 className="col-12 md:col-12 text-2xl mb-2">
                        {t("reason")}
                    </h2>
                )
            }
            case CLOSE :{
                return(
                    <div>
                        <strong>На път сте да завършите сигнала. Това действие ще прекрати дейностите по него и няма да може да се добавя нова информация.</strong>
                        <h2 className="col-12 md:col-12">
                            {t('conclusion')}
                        </h2>
                        <h4>{t('factual_background')}</h4>
                    </div>
                )
            }
            default: {
                return(
                    <h2 className="col-12 md:col-12">
                        {t("new") + " " + t('message')}
                    </h2>
                )
            }
        }
    }

    const buttonContent = () => {
        if (!messageTypeArray.includes(props.messageType)){
            return(
                <Button className="wid50 mt-2" value={t("send")} label={t("send")} tooltip={t("send_with_ctr_enter")}
                        tooltipOptions={{position: "bottom"}} onClick={sendMessage} disabled={messageText === ''}/>
            );
        } else {
            return(
                <div style={{display:"none"}}/>
            );
        }
    }

    const messageListComponent = () => {
        if (!messageTypeArray.includes(props.messageType)){
            return(
                <div className="card">
                    <h2 className="col-12 md:col-12">{t('conversation')}</h2>
                    <DataScroller className="col-12 md:col-12" rows={10} buffer={0.4}
                                  emptyMessage={emptyActivity} itemTemplate={messageTemplate}
                                  value={props.messageList}/>

                </div>
            )
        }
    }

    const newMessageComponent = () => {
        if (props.status !== 'CLOSED') {
            return (
                <div className={!messageTypeArray.includes(props.messageType) ? "card" : ""}>
                    {titleContent()}
                    <InputTextarea rows={5} className="wid100 mb-3" value={messageText} onKeyPress={handleKeyPress}
                                   onChange={event => {
                                       setMessageText(event.target.value)
                                   }}/>
                    {closingOccuranceContent()}
                    <FileUploadComponent fileUploadRef={fileUploadRef} onUpload={onUpload}/>
                    {messTypeDropDown()}
                    {buttonContent()}
                </div>
            )
        }
    }

    return(
        <div>
            {messageListComponent()}

            {newMessageComponent()}
        </div>
    );
});