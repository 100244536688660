import {InputText} from "primereact/inputtext";
import React, {useEffect, useRef, useState} from "react";
import {CompanyService} from "../services/CompanyService";
import authService from "../services/auth.service";
import {useTranslation} from "react-i18next";
import { TabView, TabPanel } from 'primereact/tabview';
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
import {CompanyInfoComponent} from "./partial/CompanyInfoComponent";
import QRCodeCanvas from "qrcode.react";
import {BASE_DOMAIN} from "../Constants";

export const CompanyInfo = (props) => {
    const {t} = useTranslation();

    useEffect(() => {
        setTimeout(() => {
            loadData();
        }, 1000);
    }, []);

    const [company, setCompany] = useState([]);
    const [companyFrontList, setCompanyFrontList] = useState([]);
    const [companyEdited, setCompanyEdited] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    const loadData = () => {
        const companyService = new CompanyService();

        companyService.getCompanyByUser(authService.getCurrentUser()?.user_name)
            .then(async response => {
                setCompany(response.data);
                await companyService.getAllFrontendsByCompany(response.data.companyId)
                    .then(response => {
                        setCompanyFrontList(response.data);
                    });
            });
    }
    const toast = useRef(null);


    const saveCompanyInfo = async () => {
        const companyService = new CompanyService();
        await companyService.edit(company)
            .then(value => {
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'The data was saved Successfully',
                    life: 5000
                });
                setCompanyEdited(false);
            })
            .catch(reason => {
                console.log("Error reason : " + reason);
            });
    }

    const handleInputChange = (e, field) => {
        let tempCompany = {...company};
        tempCompany[field] = e.target.value;
        setCompany(tempCompany);
        setCompanyEdited(true);
    }

    return(
        <div>
            <Toast ref={toast} />
            <div className="p-fluid grid">
                <div className="col-12 md:col-4">
                    <div className="card">
                        <div className="grid">
                            <h2 className="col-12 md:col-12">{t('company_details')}</h2>
                            <div className="pb-2 col-12 md:col-12">
                                <span>
                                    <label htmlFor="subject">{t('name')}</label>
                                    <InputText value={company.name} onChange={event => handleInputChange(event, 'name')}/>
                                </span>
                            </div>
                            <div className="pb-2 col-12 md:col-12">
                                <span>
                                    <label htmlFor="subject">{t('vat_number')}</label>
                                    <InputText value={company.vatNumber} onChange={event => handleInputChange(event, 'vatNumber')}/>
                                </span>
                            </div>
                            <div className="pb-2 col-12 md:col-12">
                                <span>
                                    <label htmlFor="subject">{t('phone')}</label>
                                    <InputText value={company.phone} onChange={event => handleInputChange(event, 'phone')}/>
                                </span>
                            </div>
                            <div className="pb-2 col-12 md:col-12">
                                <span>
                                    <label htmlFor="subject">{t('email')}</label>
                                    <InputText value={company.email} onChange={event => handleInputChange(event, 'email')}/>
                                </span>
                            </div>
                            <div className="pb-2 col-12 md:col-12">
                                <Button label={t("save")} onClick={saveCompanyInfo} disabled={!companyEdited}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 md:col-8">
                    <div className="card">
                        <h2 className="col-12 md:col-12">{t('public_pages')}</h2>
                        <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
                            {companyFrontList.map((tab) => {
                                return (
                                    <TabPanel key={tab.companyFrontId} header={tab.label}>
                                        <QRCodeCanvas id="canvas" value={BASE_DOMAIN + "whistle-blowing/" + tab.url} style={{display: "none"}}/>
                                        <CompanyInfoComponent tab={tab} activeTab={activeTab} companyFrontList={companyFrontList}/>
                                    </TabPanel>
                                )
                            })}
                        </TabView>
                    </div>
                </div>

            </div>
        </div>
    );
}