import { BASE_DOMAIN } from '../constants/Constants';
import api from "axios";
import authHeader from "./AuthHeader";

let service_path = 'message';

export class MessageService {

    async create(message) {
        return api.post(BASE_DOMAIN + "api/" + service_path + "/create", message,{
            headers:  authHeader()
        });
    }

    async getByOccurrenceId(occurrenceId){
        return await api.get( BASE_DOMAIN + "api/"+service_path+"/get/"+occurrenceId, {
            headers:  authHeader()
        });
    }

}