import { BASE_DOMAIN } from '../constants/Constants';
import api from "axios";
import authHeader from "./AuthHeader";

var service_path = 'activity';

export class ActivityService {

    async getByOccurrenceId(occurrenceId){
        return await api.get( BASE_DOMAIN + "api/"+service_path+"/get/"+occurrenceId, {headers: authHeader()});
    }

}