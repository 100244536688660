import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import AppWrapper from "./AppWrapper";
import './i18nextConf';
import ScrollToTop from "./ScrollToTop";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <ScrollToTop>
            <AppWrapper/>
        </ScrollToTop>
    </BrowserRouter>
);
