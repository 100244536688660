import { BASE_DOMAIN } from '../../constants/Constants';
import api from "axios";
import authHeader from "../AuthHeader";

var service_path = 'occurrence';

export class OccurrenceFrontendService {
    async create(occurrence, token) {
        return api.post(BASE_DOMAIN + "api/" + service_path + "/create/" + token, occurrence,{});
    }

    async getByUUIDAndSecret(uuid, secret){
        return await api.get( BASE_DOMAIN + "api/"+service_path+"/get/"+uuid+"/"+secret, {});
    }
}