
export class Role {

    constructor(roleId, name) {
        this.roleId = roleId;
        this.name = name;
    }

}

export default Role;