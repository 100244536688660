import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import { UserService } from '../services/UserService';
import { useTranslation } from 'react-i18next';
import 'primeflex/primeflex.min.css';
import 'primereact/resources/primereact.min.css';
import '../assets/css/Form.css';
import { RECAPTCHA_SITE_KEY } from '../Constants';
// import { Captcha } from 'primereact/captcha';

export const PasswordReset = (props) => {
	const { token, uuid } = useParams();
	const { t } = useTranslation();
	const [displayBasic, setDisplayBasic] = useState(false);
	const [resultMessage, setResultMessage] = useState(null);
	const [captchaVerified, setCaptchaVerified] = useState(null);
	const [dissabledBtn, setDissabledBtn] = useState(false);
	const defaultValues = {
		password: '',
		rePassword: ''
	};
	const [statusImage, setStatusImage] = useState(null);
	const [user, setUser] = useState([]);
	const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });
	const showResponse = (response) => {
		setCaptchaVerified(response);
	}
	 useEffect( async () => {
		const userService = new UserService();
		await userService.findUserByTokenAndStamp(uuid, token)
			.then(data => {
					setUser(data.data);
			})
			.catch(error => {
				props.history.push("/login");
				window.location.reload();
			});
	}, []);

	const onHide = (name) => {
		dialogFuncMap[`${name}`](false);
	};

	const onSubmit = async (data) => {
		const userService = new UserService();
		await userService.passwordReset(data, uuid, token)
			.then( result => {
					setStatusImage("/assets/images/transaction_successful.gif");
					setResultMessage(result.data.message);
				}
			)
			.catch( error =>{
					setStatusImage("/assets/images/transaction_failed.gif");
					setResultMessage("Възникна грешка. Моля опитайте отново");
				}
			);
		dialogFuncMap['displayBasic'](true);
	};
	const dialogFuncMap = {
		'displayBasic': setDisplayBasic
	}
	const handleBtn = (text) =>{
		setDissabledBtn(
			!defaultValues.password && !defaultValues.rePassword &&
		(defaultValues.password == defaultValues.rePassword));
	}
	const getFormErrorMessage = (name) => {
		return errors[name] && <small className="p-error">{errors[name].message}</small>
	};
	const passwordHeader = <h6>{t('pick_password')}</h6>;
	const passwordFooter = (
		<React.Fragment>
			<Divider />
			<p className="p-mt-2">{t('suggestions')}</p>
			<ul className="p-pl-2 p-ml-2 p-mt-0" style={{ lineHeight: '1.5' }}>
				<li>{t('alo_lowercase')}</li>
				<li>{t('alo_uppercase')}</li>
				<li>{t('alo_numeric')}</li>
				<li>{t('alo_eight')}</li>
			</ul>
		</React.Fragment>
	);

	return (
		<div className="form-holder">
			<div className="flex justify-content-center">
				<div className="card">
					<div className="text-center mb-5">
						<img src={t('logo_vertical_url')} alt="Confidential Reportyng System logo" height={300}
							 className="marginAuto mt-2 mb-3"/>
						<div className="text-900 text-3xl font-medium mb-3">{t('forgot_password')}</div>
					</div>
					<form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
						<div className="p-field field">
                            <span className="p-float-label">
                                <Controller name="password" control={control} rules={{ required: 'Password is required.' }} render={({ field, fieldState }) => (
									<Password id={field.name} {...field} toggleMask
											  className={classNames({ 'p-invalid': fieldState.invalid })}
											  feedback={true} header={passwordHeader} footer={passwordFooter}/>
								)} />
                                <label htmlFor="password" className={classNames({ 'p-error': errors.password })}>{t('password')}*</label>
                            </span>
							{getFormErrorMessage('password')}
						</div>
						<div className="p-field field">
                            <span className="p-float-label">
                                <Controller name="rePassword" control={control}
											rules={{ required: 'Password is required.' }}
											render={({ field, fieldState }) => (
									<Password id={field.name} {...field} toggleMask
											  className={classNames({ 'p-invalid': fieldState.invalid })}
											  feedback={false}/>
								)} />
                                <label htmlFor="rePassword" className={classNames({ 'p-error': errors.password })}>{t('re-password')}*</label>
                            </span>
							{getFormErrorMessage('rePassword')}
						</div>
						{getFormErrorMessage('login')}
						{/*<Captcha siteKey={RECAPTCHA_SITE_KEY} onResponse={showResponse} />*/}
						<hr/>
						<Button type="submit" label={t('password_request')} icon="pi pi-user" className="w-full p-mt-4"
								disabled={dissabledBtn}/>
					</form>
					<Dialog header={resultMessage} visible={displayBasic}
							footer="  " style={{ width: '40vw', textAlign: 'center'}}
							onHide={() => onHide('displayBasic')}>
						<img src={statusImage} width="60%"/>
					</Dialog>
				</div>
			</div>
		</div>
	);
}