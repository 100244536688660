import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "primereact/button";
import '../assets/css/DataView.css';
import {Dialog} from "primereact/dialog";
import {OccurrenceDataTable} from "./partial/OccurrenceDataTable";
import {OccurrenceSubmitComponent} from "./partial/OccuranceSubmitComponent";
import authService from "../services/auth.service";
export const OccurrenceComponent = () => {

    const { t } = useTranslation();
    const [newOccurrence, setNewOccurrence] = useState([]);
    const [displayResponsive, setDisplayResponsive] = useState(false);
    const childRef = useRef();

    const dialogFuncMap = {
        'displayResponsive': setDisplayResponsive
    }

    const showNewOccurrenceDialog = () => {
        setNewOccurrence("");
        dialogFuncMap['displayResponsive'](true);
    }

    const hideNewOccurrenceDialog = () => {
        dialogFuncMap['displayResponsive'](false);
    }

    const onSavedSuccess = (response) => {
        childRef.current.reloadList();
        console.log(response);
        hideNewOccurrenceDialog();
    }

    return (
        <div className="occurrence-container">
            <div className="p-dataview-header">
                <h1>{t("occurrences")}</h1>
                <Button icon="pi pi-plus" label={t("create")+" "+t("occurrence")} onClick={() => showNewOccurrenceDialog()}/>

                <Dialog header={t("submit_occurrence")} visible={displayResponsive} onHide={() => hideNewOccurrenceDialog()}
                        breakpoints={{'560px': '75vw'}} style={{width: '50vw',minWidth: "400px"}} >
                    <OccurrenceSubmitComponent username={authService.getCurrentUser()?.user_name} onSaved={onSavedSuccess} source="OPERATOR"/>
                </Dialog>
            </div>
                <div className="card">
                    <div>
                        <OccurrenceDataTable rows="10" ref={childRef}/>
                    </div>
                </div>
        </div>
    );
}