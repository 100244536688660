import React from 'react';
import App from './App';
import { Login } from './pages/Login';
import { Register } from './pages/Register';
import { ForgotPassword } from './pages/ForgotPassword';
import WhistleBlowing from "./pages/WhistleBlowing";
import WebForm from "./pages/WebForm";
import Company from "./Company";
import WhistleBlowingSubmit from "./pages/WhistleBlowingSubmit";
import WhistleBlowingCheck from "./pages/WhistleBlowingCheck";
import {Route} from "react-router-dom";
import WhistleBlowingSubmitted from "./pages/WhistleBlowingSubmitted";
import {Logout} from "./components/Logout";
import {PasswordReset} from "./pages/PasswordReset";

const AppWrapper = () => {
	return (
		<div>
			<Route path="/login" component={Login}/>
			<Route path="/logout" component={Logout}/>
			<Route path="/register" component={Register}/>
			<Route path="/account-activation/:uuid/:token" component={PasswordReset}/>
			<Route path="/forgotPassword" component={ForgotPassword}/>
			<Route path="/whistle-blowing/:token/check" component={WhistleBlowingCheck} exact={true}/>
			<Route path="/whistle-blowing/:token/submit" component={WhistleBlowingSubmit} exact={true}/>
			<Route path="/whistle-blowing/:token/submitted" component={WhistleBlowingSubmitted} exact={true}/>
			<Route path="/whistle-blowing/:token" component={WhistleBlowing} exact={true}/>
			<Route path="/web-form" component={WebForm}/>
			<Route path="/company/**" component={Company} exact={true}/>
			<Route path="/company" component={Company} exact={true} strict={true}/>
			<Route path="/" component={Login} exact={true}/>
		</div>
	);
}

export default AppWrapper;