import {LanguageSw} from "../components/partial/LanguageSw";
import React from "react";
import {useTranslation} from "react-i18next";

export const WhistleBlowingHeader = (props) => {

    const { t } = useTranslation();
    return (
        <div className="wb-header grid">
            {/* Logo put here */}
            <div className="col-12 md:col-8 floatLeft">
                <img src={t('logo_url')} alt="Confidential Reportyng System logo" height={45}/>
            </div>
            {/* Language Switch */}
            <div className="col-12 md:col-4 ">
                <LanguageSw className="wid80 language-switch floatRight"/>
            </div>
        </div>
    );
}