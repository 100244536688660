import QRCodeCanvas from "qrcode.react";
import {BASE_DOMAIN} from "../../Constants";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Link} from "react-router-dom";
import {Editor} from "primereact/editor";
import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {CompanyFrontendService} from "../../services/CompanyFrontendService";
import {Document, Font, Image, Page, Text, usePDF, View} from "@react-pdf/renderer";

export const CompanyInfoComponent = (props) => {
    const {t} = useTranslation();
    const [copied, setCopied] = useState(false);
    const [frontEdited, setFrontEdited] = useState(false);
    const [inUrl, setInUrl] = useState();
    const toast = useRef(null);

    Font.register({
        family: "Roboto",
        src:
            "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"
    });
    // const div = document.createElement('div');
    // div.innerHTML = <QRCodeCanvas id="canvas" value={BASE_DOMAIN + "whistle-blowing/" + props.tab.url} />;
    // const qrCodeCanvas = document.fromString(qrCode);
    // const qrCodeCanvas = document.querySelector('canvas');
    // const qrCodeDataUri = props.qrCodeCanvas.toDataURL('image/jpg', 0.3);
    // let qrCodeCanvas = "";
    // let qrCodeDataUri = "";
    const QRDoc = (<div>
        <Document>
        <Page>
            <View style={{ color: 'black', textAlign: 'center', margin: 30, fontFamily : "Roboto"}}>
                <Text style={{fontSize:"20px", marginBottom:"20px"}}>{'Подайте Вашият сигнал тук :'}</Text>
                {/*<Image src={qrCodeDataUri} style={{width: "150", margin: "10px auto"}}/>*/}
                <strong>
                    <Text style={{marginTop:"20px", fontSize:"14px", fontWeight:"bold", color:"red"}}>{props.tab.url}</Text>
                </strong>
            </View>
        </Page>
    </Document>
    </div>);
    let [instance, updateInstance] = usePDF({ document: QRDoc });

    const copyKey = (e) => {
        setCopied(true);
        navigator.clipboard.writeText(e);
        toast.current.show({severity:'success', summary: 'Success', detail:'The password was copied Successfully', life: 5000});
    }

    const handleCopy = async (e) => {
        copyKey(e.target.value);
    }

    const handleBtnCopy = (e) => {
        copyKey(e);
    }

    const saveThatPage = () => {
        const companyFrontendService = new CompanyFrontendService();
        companyFrontendService.edit(props.companyFrontList[props.activeTab])
            .then(value => {
                toast.current.show({severity:'success', summary: 'Success', detail:'The data was saved Successfully', life: 5000});
                setFrontEdited(false);
            })
            .catch(reason => {
                console.log("Error reason : " + reason);
            });
    }

    const handleEditorChange = (e) => {
        props.tab.text = e.htmlValue;
        setFrontEdited(true);
    }

    function HandleQR(e){
        const qrCodeCanvas = document.querySelector('canvas');
        const qrCodeDataUri = qrCodeCanvas.toDataURL('image/jpg', 0.3);

        const readyDoc = (<Document>
                <Page>
                    <View style={{ color: 'black', textAlign: 'center', margin: 30, fontFamily : "Roboto"}}>
                        <Text style={{fontSize:"20px", marginBottom:"20px"}}>{'Подайте Вашият сигнал тук :'}</Text>
                        <Image src={qrCodeDataUri} style={{width: "150", margin: "10px auto"}}/>
                        <strong>
                            <Text style={{marginTop:"20px", fontSize:"14px", fontWeight:"bold", color:"red"}}>{e}</Text>
                        </strong>
                    </View>
                </Page>
            </Document>);
        updateInstance( readyDoc );
        setInUrl(instance.url);
        // props.history.push(instance.url.toString());
    }

    return (
        <div>
            {/*<QRCodeCanvas id="canvas" value={BASE_DOMAIN + "whistle-blowing/" + props.tab.url} />*/}
            <h3 className="col-12 md:col-12">{t('link')}</h3>
            <div className="p-inputgroup">
                <span className="p-inputgroup-addon"> <i className="pi pi-link"></i></span>
                <InputText id="description"  className="wid100 font-bold text-center"
                           onClick={handleCopy}
                           value={BASE_DOMAIN + "whistle-blowing/" + props.tab.url}/>
                <Button icon="pi pi-copy" onClick={e => handleBtnCopy(BASE_DOMAIN + "whistle-blowing/" + props.tab.url)}/>
                <a href={inUrl} target={"_blank"}>
                    <Button icon="pi pi-qrcode" className="p-button-info" onClick={event => HandleQR(BASE_DOMAIN + "whistle-blowing/" + props.tab.url)} />
                </a>
                <Link to={"/whistle-blowing/" + props.tab.url} target="_blank">
                    <Button icon="pi pi-eye" className="p-button-success "/>
                </Link>
            </div>
            <h3 className="col-12 md:col-12">{t('content')}</h3>

            <Editor style={{ height: '320px' }} value={props.tab.text}
                    onTextChange={(e) => {handleEditorChange(e)}} />

            <Button label={t("save")} onClick={saveThatPage} className="mt-3" disabled={!frontEdited}/>
            {/*<QRCodeCanvas id="canvas" value={BASE_DOMAIN + "whistle-blowing/" + props.tab.url} />*/}
            {/*style={{display: "none"}}*/}
        </div>
    )
}